<template>
    <div>
        <div class="is-pulled-right">
            <div class="button " :class="{'is-success' : include_archived == 1}" @click="toggleIncludeArchived">Include Archived</div>
        </div>
        
        <h2 class="title">Search Members <span v-if="members.length > 0">( {{ members.length}} )&nbsp;&nbsp;&nbsp;&nbsp;</span>

            <button class="button is-link" @click="resetForm()">Reset Form</button>
        </h2>
        
       
        <form @submit.prevent="searchMembers()" class="">
            <div class=" has-addons field">
                <div class="control is-expanded">
                    <input class="input" type="text" placeholder="search ..." ref="member_search" autofocus v-model="member_search" >
                </div>
                <div class="control">
                    <button class="button is-success" type="submit">Search</button>
                </div>
            </div>
        </form>
        <br />
        <div class="buttons" v-if="1==2">
            <div class="button is-success" @click="showMembers=true">All Members</div>
            <div class="button is-success" @click="showMembers=true">Working in Next 7 Days</div>
            <div class="button is-success" @click="showMembers=true">Worked in Past 7 Days</div>
            <div class="button is-success" @click="showMembers=true">Can Work Weekends</div>
            <div class="button is-success" @click="showMembers=true">Can Work Nights</div>
        </div>

        <div class="notification is-warning" v-if="loading == true">
            Loading members ...
        </div>
        <div class="notification is-danger" v-if="loading == false && this.searchPerformed == true && members.length == 0">
            No members can be found matching your search criteria
        </div>

        <table class="table is-fullwidth is-hoverable is-size-7" v-if="loading == false && this.members.length > 0">
            <thead>
                <tr>
                    <td width="10" style="display:none"><input type="checkbox" class="checkbox"/></td>
                    <th>Forename</th>
                    <th>Surname</th>
                    <th>Grade</th>
                    <th>Town</th>
                    <th>Telephone</th>
                    <th>Email</th>
                    <th>DOB</th>
                    <th width="50"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in this.members" :key="row.id" :class="{'has-background-danger-dark' : row.blocked==1, 'has-text-white' : row.blocked==1, 'has-background-warning' : row.archived==1}">
                    <td style="display:none"><input type="checkbox" class="checkbox" v-if="row.blocked==0"/></td>
                    
                    <td>{{ row.forename }}</td>
                    <td>{{ row.surname}}</td>
                    <td><MemberGradeTags :member="row"/></td>
                    <td style="cursor:pointer" :title="row.address1 + ', ' + row.address2 + ', ' + row.address3 + ', ' + row.postcode">{{ row.address3 }}</td>
                    <td>{{ row.mobile }}</td>
                    <td><A :href="'mailto:' + row.email">{{ row.email }}</A></td>
                    <td>{{ row.date_of_birth_formatted }}</td>
                    
                    <td><router-link :to="'/members/' + row.id"><button class="button is-small is-link" >View</button></router-link></td>
                </tr>
                
                
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="8"><button class="button is-link" @click="showModal=true" :disabled="1==1" v-if="1==2">Send Email To Selected</button></td>
                </tr>
            </tfoot>
        </table>

        <div class="modal" :class="{'is-active': showModal==true}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Send Member Email</p>
                <button class="delete" aria-label="close" @click="showModal=false"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label">Subject</label>
                        <div class="control">
                            <input class="input" type="text" placeholder="Message From Balmoral Healthcare">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Content</label>
                        <div class="control">
                            <textarea class="textarea" placeholder="Email content here"></textarea>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="showModal=false">Send Email</button>
                <button class="button" @click="showModal=false">Cancel</button>
                </footer>
            </div>
            </div>

    </div>
</template>

<script>
//import MemberService from '@/services/MemberService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
    
      name: 'MembersDashboard',
        mixins: [MemberMixin],
        components: {
            MemberGradeTags
        },
        data: function () {
            return {
                member_search: '',
                   members: [],
                   loading: false,
                   searchPerformed: false,
                   showModal: false,
                   order_by: 'surname ASC, forename ASC',
                   accessToken: '',
                   include_archived: 0
            }
        },
        methods: {
            toggleIncludeArchived()
            {
                if(this.include_archived == 1)
                {
                    this.include_archived = 0
                }
                else{
                    this.include_archived = 1
                }
                this.getMembers(this.order_by, this.include_archived);
            },
            resetForm()
            {
                this.member_search = '';
                localStorage.member_search = '';
                this.searchPerformed = false
                this.members = [];

                this.$refs.member_search.focus();
            },
            searchMembers()
            {
                localStorage.member_search = this.member_search;
                this.getMembers(this.order_by, this.include_archived);
            }
            
        },
        created() {
            this.getAccessToken()
                .then(() => { 
                    this.getMembers(this.order_by);
                });
        
        },
        mounted() {
            if (localStorage.member_search) {
                this.member_search = localStorage.member_search;
                
            }
            else{
                this.member_search = ''
            }

           
        },
       
    }
</script>