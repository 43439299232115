<template>
    <div class="modal " :class="{'is-active': showNoteModal==true}">
        <div class="modal-background"></div>
        <div class="modal-card"  style="width:840px;max-width:100%">
            <header class="modal-card-head">
                <p class="modal-card-title">Add Note</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body" >
                <div class="notification is-warning" v-if="loading == true">
                    Loading member details ...
                </div>
                <form @submit.prevent="saveMemberNote()" v-if="loading==false">
                    <div class="field">
                    <label class="label">Save For Member</label>
                    <div class="control">
                        <input class="input"  :value="this.member.forename + ' ' + this.member.surname" disabled>
                    </div>
                    </div>
                    <div class="field">
                    <label class="label">Note Type</label>
                    <div class="select is-fullwidth">
                        <select class="input "  v-model="note_type_id" required>
                            <option :value="row.id" v-for="row in note_types" :key="row.id">{{ row.name}}</option>
                        </select>
                    </div>
                    </div>
                    <div class="field">
                    <label class="label">Subject</label>
                    <div class="control">
                        <input class="input" type="text" v-model="subject" >
                    </div>
                    </div>
                    <div class="field" >
                        <label class="label">Note Body</label>
                        <div class="control">
                            <textarea class="textarea" placeholder="" v-model="body" required></textarea>
                        </div>
                    </div>
                    <div class="field is-grouped">
                    <div class="control">
                        <button class="button is-success" :disabled="working==true">Save Note</button>
                    </div>
                    
                    </div>
                </form>

                
            </section>
            
            <footer class="modal-card-foot" >
            <button class="button" @click="closeModal()" :disabled="working==true">Cancel</button>
            </footer>
        </div>
        
       
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberService from '@/services/MemberService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
    
      name: 'NoteModal',
        mixins: [MemberMixin],
        components: {
            
        },
        props: ['member_id', 'showNoteModal'],
        data: function () {
            return {
                   search: '',
                   booking: [],
                   searchPerformed: false,
                   assigned:false,
                   alertText: '',
                   member: [],
                   working:false,
                   loading: true,
                   subject: '',
                   body: '',
                   note_types: [],
                   note_type_id: 2
            }
        },
        methods: {
            async getMember() {
                this.loading=true
                
                UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id)
                .then(
                    (member => {
                        this.$set(this, "member", member);
                        this.loading = false
                    }).bind(this)
                );
            },
            async getNoteTypes() {
                
                UtilityService.getSimpleApiData(this.accessToken, "note_types")
                .then(
                    (note_types => {
                        this.$set(this, "note_types", note_types);
                        
                    }).bind(this)
                );
            },
            
           
            async saveMemberNote() {
                this.working = true

                
                    var formdata = { 
                        note_type_id : this.note_type_id,
                        member_id : this.member_id,
                        subject : this.subject,
                        body : this.body,
                    }; 

                    MemberService.saveMemberNote(this.accessToken, formdata)
                    .then(
                        () => {
                            this.working=false;
                            this.body = ''
                            this.subject = ''
                            alert("Note Saved")
                            this.closeModal()

                        }
                    ).catch((error) => { 
                        
                    if (error.response.status == 409) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.response.data.message)
                    } 
                    this.working=false;
                });
                
            },
            
            async closeModal() {
                this.subject = ''
                this.body = ''

                this.$emit('close')
            },
            
        },
        computed : {
           
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                
            });
        
        },
        watch: {
            
            showNoteModal() {
                
                this.getNoteTypes();
                this.getMember();
                
            }
        },
    }
</script>