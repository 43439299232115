<script>
import MemberGradeModal from '@/components/Generic/MemberGradeModal.vue';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
  data() {
    return {
      count: 0,
      member_grade_id: '',
      showModal: false
    }
  },
  methods: {
        showMemberGradeModal(id) {
            this.member_grade_id = id 
            this.showModal = true
        },
    },
    mixins: [MemberMixin],
  components: {
    MemberGradeModal
    },
  props: ['member', 'restrict_grade_id'],
  computed : {
      filtered_grades: function () {
        
        let filtered_grades = this.member.grades 

        if(this.restrict_grade_id > 0)
        {
          filtered_grades = this.member.grades.filter(grade => grade.grade_id == this.restrict_grade_id);
        }
        
          return filtered_grades
      },
    },
}
</script>



<template>
  <div>
    <div class="tags mb-0">
        <span class="tag mb-1"  style="cursor:pointer" @click="showMemberGradeModal(grade.id)" v-for="grade in filtered_grades" :key="grade.id" :class="{'is-success': grade.valid==1, 'is-danger': grade.valid==0}">
        {{ grade.code }}  
        </span>
    </div>

    <MemberGradeModal :showModal="showModal" @closeModal="closeModal" :member_grade_id="member_grade_id"/>
  </div>
</template>