<template>
    <div>
        <div v-if="requires_niscc == 1">
            <h2 class="title is-size-4">NISCC Skills Sheet</h2>
            <div class="notification is-warning" v-if="loading_niscc==true">Loading NISCC skills sheet ... </div>
            <div class="notification is-info" v-if="loading_niscc==false && niscc_skills_sheet_categories.length == 0">There are no NISCC skills sheet details to show. </div>
            <table class="table  is-hoverable is-fullwidth" >
                <tbody>
                <template v-for=" row in niscc_skills_sheet_categories " >
                    <tr :key="'title' + row.id">
                        <th colspan="2">{{ row.name}}</th>
                    </tr>
                    <tr v-for="question in row.questions " :key="'question' + question.id">
                        <td>{{ question.name}}</td>
                        <td width="25%"><button class="button is-small" :class="{'is-success' : question.answer == 1}" :disabled="working==true" >Yes</button>
                            <button class="button is-small " :class="{'is-danger' : question.answer == 0}" :disabled="working==true" >No</button></td>
                    </tr>
                    
                </template>
                
            </tbody>
            </table>
            <hr />
        </div>

        <div v-if="requires_nmc == 1">
            <h2 class="title is-size-4">NMC Skills Sheet</h2>
            <div class="notification is-warning" v-if="loading_nmc==true">Loading NMC skills sheet ... </div>
            <div class="notification is-info" v-if="loading_nmc==false && nmc_skills_sheet_categories.length == 0">There are no NMC skills sheet details to show. </div>
            <table class="table  is-hoverable is-fullwidth" >
                <tbody>
                <template v-for=" row in nmc_skills_sheet_categories " >
                    <tr :key="'title' + row.id">
                        <th colspan="2">{{ row.name}}</th>
                    </tr>
                    <tr v-for="question in row.questions " :key="'question' + question.id">
                        <td>{{ question.name}}</td>
                        <td width="25%">
                            <button class="button is-small" :class="{'is-success' : question.answer == 1}" :disabled="working==true" >1</button>
                            <button class="button is-small " :class="{'is-warning' : question.answer == 2}" :disabled="working==true" >2</button>
                            <button class="button is-small " :class="{'is-warning' : question.answer == 3}" :disabled="working==true" >3</button>
                            <button class="button is-small " :class="{'is-danger' : question.answer == 4}" :disabled="working==true" >4</button>
                        </td>
                    </tr>
                    
                </template>
                
            </tbody>
            </table>
            <hr />
        </div>

        <div v-if="requires_cop == 1">
            <h2 class="title is-size-4">Paramedic Skills Sheet</h2>
            <div class="notification is-warning" v-if="loading_paramedic==true">Loading Paramedic skills sheet ... </div>
            <div class="notification is-info" v-if="loading_paramedic==false && paramedic_skills_sheet_categories.length == 0">There are no Paramedic skills sheet details to show. </div>
            <table class="table  is-hoverable is-fullwidth" >
                <tbody>
                <template v-for=" row in paramedic_skills_sheet_categories " >
                    <tr :key="'title' + row.id">
                        <th colspan="2">{{ row.name}}</th>
                    </tr>
                    <tr v-for="question in row.questions " :key="'question' + question.id">
                        <td>{{ question.name}}</td>
                        <td width="25%">
                            <button class="button is-small" :class="{'is-success' : question.answer == 1}" :disabled="working==true" >1</button>
                            <button class="button is-small " :class="{'is-warning' : question.answer == 2}" :disabled="working==true" >2</button>
                            <button class="button is-small " :class="{'is-warning' : question.answer == 3}" :disabled="working==true" >3</button>
                            <button class="button is-small " :class="{'is-danger' : question.answer == 4}" :disabled="working==true" >4</button>
                        </td>
                    </tr>
                    
                </template>
                
            </tbody>
            </table>
         </div>

    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberService from '@/services/MemberService.js';
import UtilityService from '@/services/UtilityService.js';

export default {
    name: 'Education',
  data() {
    return {
        loading_niscc: true,
        loading_nmc: true,
      loading_paramedic: true,
      working: false,
      niscc_skills_sheet_categories: [],
      nmc_skills_sheet_categories: [],
      paramedic_skills_sheet_categories: [],

      
    }
  },
  mixins: [MemberMixin],
  props: ['member_id', 'requires_cop', 'requires_niscc', 'requires_nmc'],
  methods: {
        
       
        async getNisccMemberSkillsSheet() {
            if(this.requires_niscc == 1)
            {
                this.loading_niscc=true
                UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/skills_sheets?niscc_only=1")
                .then(
                    (skills_sheet_categories => {
                        this.$set(this, "niscc_skills_sheet_categories", skills_sheet_categories);
                        this.loading_niscc=false
                    }).bind(this)
                );
            }
        },
        async getNurseMemberSkillsSheet() {
            if(this.requires_nmc == 1)
            {
                this.loading_nmc=true
                UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/skills_sheets?nmc_only=1")
                .then(
                    (skills_sheet_categories => {
                        this.$set(this, "nmc_skills_sheet_categories", skills_sheet_categories);
                        this.loading_nmc=false
                    }).bind(this)
                );
            }
        },
        async getParamedicMemberSkillsSheet() {
            if(this.requires_cop == 1)
            {
                this.loading_paramedic=true
                UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/skills_sheets?paramedic_only=1")
                .then(
                    (skills_sheet_categories => {
                        this.$set(this, "paramedic_skills_sheet_categories", skills_sheet_categories);
                        this.loading_paramedic=false
                    }).bind(this)
                );
            }
        },
      
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getNisccMemberSkillsSheet()
                this.getNurseMemberSkillsSheet()
                this.getParamedicMemberSkillsSheet()
            });
        
    },
    computed : {
       
    }
}
</script>