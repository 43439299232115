<template>
    <div>
    <div class="modal" :class="{'is-active': showModal==true}">
        <div class="modal-background"></div>

        <div class="modal-card" v-if="loading==true">
            <div class="notification is-warning" v-if="loading==true">
                Loading details ...
            </div>
        </div>
        <div class="modal-card" v-if="loading==false">
            <header class="modal-card-head">
                <div class="modal-card-title is-vcentered">
                    <span>{{ member_grade.grade_name }} </span>
                    <span class="button is-danger is-small " v-if="member_grade.valid==0">Invalid</span>
                    <span class="button tag is-success is-small" v-if="member_grade.valid==1">Valid</span>
                </div>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body">
                <table class="table is-striped is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <th>Course</th>
                            <th>Date</th>
                            <th width="65">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template >
                            <tr v-for="row in missing_courses" :key="row.id">
                                <td>{{ row.name}}</td>
                                <td>{{ row.date }}</td>
                                <td>
                                    <span class="tag is-warning is-small " v-if="row.valid==0">Invalid</span>
                                    <span class="tag tag is-success is-small" v-if="row.valid==1">Valid</span>
                                    <span class="tag tag is-danger is-small" v-if="row.valid==-1">Missing</span>
                                </td>
                            </tr>
                        </template>
                        <template >
                            <tr v-for="row in invalid_courses" :key="row.id">
                                <td>{{ row.name}}</td>
                                <td>{{ row.date }}</td>
                                <td>
                                    <span class="tag is-warning is-small " v-if="row.valid==0">Expired</span>
                                    <span class="tag tag is-success is-small" v-if="row.valid==1">Valid</span>
                                </td>
                            </tr>
                        </template>
                        <template >
                            <tr v-for="row in valid_courses" :key="row.id">
                                <td>{{ row.name}}</td>
                                <td>{{ row.date }}</td>
                                <td>
                                    <span class="tag is-warning is-small " v-if="row.valid==0">Expired</span>
                                    <span class="tag tag is-success is-small" v-if="row.valid==1">Valid</span>
                                </td>
                            </tr>
                        </template>
                       
                    </tbody>
                </table>
            </section>
            <footer class="modal-card-foot is-fullwidth" style="display:block">
                <button class="button" @click="closeModal()">Close</button>

                <button class="button is-link is-pulled-right" @click="openEmailModal()">Send Email</button>
            </footer>
        </div>
    </div>

    <EmailModal :member_id="this.member_grade.member_id" :showEmailModal="showEmailModal" @close="closeEmailModal" :emailText="emailText" :emailSubject="emailSubject"/>
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';
//import BookingService from '@/services/BookingService.js';

import EmailModal from '@/components/Members/EmailModal.vue'

export default {
    
      name: 'MemberGradeModal',
        mixins: [MemberMixin],
        props: ['showModal', 'member_grade_id'],
        data: function () {
            return {
                  member_grade: [],
                   loading: false,
                   showEmailModal: false,
                   emailText: '',
                   emailSubject: 'Careskills - Trainings Due'
                   
            }
        },
        components: {
            EmailModal,
        },
        methods: {
            openEmailModal() {
                this.setEmailText()
                this.showEmailModal = true
            },
            async getMemberGrade() {
                this.loading=true
                this.member_grade = []
                if(this.member_grade_id > 0)
                {
                    UtilityService.getSimpleApiData(this.accessToken, "member_grades/" + this.member_grade_id)
                    .then(
                        (member_grade => {
                            this.$set(this, "member_grade", member_grade);
                            this.loading=false
                        }).bind(this)
                    );
                }
            },
            setEmailText() {
                this.emailText =  'The following items are due on your profile. I have updated all courses to your eLearning to avoid automatic shift cancellations or blocks. Can you please log on to your eLearning and complete the following Trainings urgently.\r\n\r\n'
                
                

                if (Object.keys(this.missing_courses).length) 
                {
                    this.emailText += "<strong>Required Training</strong>\r\n"

                    for (const item in this.missing_courses)
                    {
                        //console.log(this.missing_courses[item])
                        this.emailText += this.missing_courses[item].name + '\r\n';
                    }
                }

                if (Object.keys(this.invalid_courses).length) 
                {
                    this.emailText += "\r\n<strong>Expired</strong>\r\n"

                    for (const item in this.invalid_courses)
                    {
                        //console.log(this.invalid_courses[item])
                        this.emailText += this.invalid_courses[item].name + '\r\n';
                    }
                }

            },
            async closeModal() {
                this.$emit('closeModal')
            },
            filterObjectByValid(inputObject) {
                const filteredObject = {};
                for (const key in inputObject) {
                    if (inputObject[key].valid === 1) {
                    filteredObject[key] = inputObject[key];
                    }
                }
                return filteredObject;
            },
            filterObjectByInValid(inputObject) {
                const filteredObject = {};
                for (const key in inputObject) {
                    if (inputObject[key].valid === 0) {
                    filteredObject[key] = inputObject[key];
                    }
                }
                return filteredObject;
            },
            filterObjectByMissing(inputObject) {
                const filteredObject = {};
                for (const key in inputObject) {
                    if (inputObject[key].valid === -1) {
                    filteredObject[key] = inputObject[key];
                    }
                }
                return filteredObject;
            },
            
            
        },
        computed: {
            // Computed property to filter the object based on the 'valid' column
            valid_courses() {
            return this.filterObjectByValid(this.member_grade.courses);
            },
            invalid_courses() {
            return this.filterObjectByInValid(this.member_grade.courses);
            },
            missing_courses() {
            return this.filterObjectByMissing(this.member_grade.courses);
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberGrade();

            });
        
        },
        watch: {
            member_grade_id: function(){
                this.getMemberGrade();
            },
            missing_courses: function(){
                this.setEmailText();
            },
            
        }
    }
</script>