<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading training ...
        </div>
        
        <div v-if="loading==false">
            <button class="button  is-success" @click="openMemberCourseModal('')">Add New Training</button>
            <div class="is-pulled-right"><input class="input" v-model="search" placeholder="filter courses ..."/></div>
            <table class="table is-fullwidth is-hoverable" >
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Course</th>
                        <th>Date Complete</th>
                        <th>Valid For</th>
                        <th>Expiry Date</th>
                        <th width="50">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in filtered_training" :key="row.id">
                        <td>
                            <button class="button is-small is-success" v-if="row.valid==1">Valid</button>
                            <button class="button is-small is-danger" v-if="row.expired==1">Expired</button>
                            <button class="button is-small is-warning" v-if="row.no_date==1">Not Taken</button>
                        </td>
                        <td>{{ row.name }}</td>
                        <td>{{ row.date }}</td>
                        <td><span v-if="row.valid_for_months > 0">{{ row.valid_for_months }} months</span></td>
                        <td>{{ row.expiry_date }}</td>
                        <td><button class="button is-small is-info" @click="openMemberCourseModal(row.course_id)">Update</button></td>
                    </tr>
                    
                </tbody>
            </table>

            <button class="button  is-success" @click="openMemberCourseModal('')">Add New Training</button>
        </div>


        <AddMemberCourseModal :showModal="showModal" @closeModal="closeModal" :member_id="member_id" :course_id="course_id"/>
        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

import AddMemberCourseModal from '@/components/Members/AddMemberCourseModal.vue';

export default {
    
      name: 'MemberTraining',
        mixins: [MemberMixin],
        components: {
            AddMemberCourseModal
        },
        data: function () {
            return {
                   search: '',
                   training: [],
                   loading: true,
                   member_id : this.$route.params.id,
                   showModal : false,
                   course_id: '',
                   
            }
        },
        methods: {
            openMemberCourseModal(course_id) {
                this.course_id = course_id;
                this.showModal = true
            },
            async getMemberTraining() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/courses")
                .then(
                    (training => {
                        this.$set(this, "training", training);
                        this.loading=false
                    }).bind(this)
                );
            },
            closeModal() {
                this.getMemberTraining();
                this.showModal = false
            }
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberTraining();
            });
        
        },
        computed : {
            filtered_training: function () {
                let filtered_training = this.training

                if(this.search != '' && this.search != null)
                {
                    filtered_training = this.training.filter(row => row.name.toLowerCase().includes(this.search.toLowerCase()))
                }
               

                return filtered_training
            
            }
        },
    }
</script>