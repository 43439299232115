<template>
    <div>
        <h3 class="title is-4">Member Documents 
            <button class="button is-success is-small" @click="showAddForm = true" :disabled="loading==true || working==true" v-if="showAddForm == false">Add New Document</button>
            <button class="button is-danger is-small" @click="showAddForm = false" :disabled="loading==true || working==true" v-if="showAddForm == true">Exit without Saving</button>
        </h3>
        
        <div class="notification is-warning" v-if="loading==true">Loading documents ... </div>
        <div class="notification is-info" v-if="loading==false && documents.length == 0 && this.showAddForm == false">There are no documents to show. </div>
        <table class="table is-fullwidth is-hoverable" v-if="loading==false && documents.length > 0 && this.showAddForm == false">
            <thead>
                <tr>
                    <th>Document Type</th>
                    <th>Received</th>
                    <th>Expiry</th>
                    <th>Notes</th>
                    <th>User</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in documents" :key="row.id">
                    <td>{{ row.document_type_name}}</td>
                    <td>{{ row.received_date}}</td>
                    <td>{{ row.expiry_date }}</td>
                    <td>{{ row.notes }}</td>
                    <td>{{ row.user_forename }} {{ row.user_surname }}</td>
                    <td ><button class="button is-small is-danger" @click="removeDocument(row.id)">Remove</button></td>
                </tr>
                
            </tbody>
        </table>

        <div class="box" v-if="this.showAddForm == true">	
              <form @submit.prevent="saveMemberDocument()">
                    <div class="field" >
                          <label class="label">Document Type</label>
                          <div class="control">
                              <select class="input"  v-model="document_type_id"  required>
                                <option value="">Please select</option>
                                <option :value="row.id" v-for="row in document_types" :key="row.id">{{ row.name }}</option>
                              </select>
                             
                          </div>
                      </div>

                      <div class="field" >
                          <label class="label">Received Date</label>
                          <div class="control">
                              <input class="input" type="date" v-model="received_date" required  >
                          </div>
                      </div>

                      <div class="field" >
                          <label class="label">Expiry Date</label>
                          <div class="control">
                              <input class="input" type="date" v-model="expiry_date"   >
                          </div>
                      </div>
                     
                      <div class="field" >
                          <label class="label">Notes</label>
                          <div class="control">
                              <input class="input" type="text" v-model="notes"  >
                             
                          </div>
                      </div>

                      <div class="field is-grouped" >
                          <div class="control">
                              <button type="submit" class="button is-success" :disabled="working===true">Save Document</button>
                          </div>
                      </div>
                  </form>
            
          </div>

    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';


import MemberService from '@/services/MemberService.js';
import UtilityService from '@/services/UtilityService.js';

export default {
    name: 'MemberDocuments',
  data() {
    return {
      loading: true,
      working: false,
      documents: [],
      showAddForm: false,
      document_types: [],
      document_type_id: '',
      notes: '',
      received_date: '',
      expiry_date: ''
      
    }
  },
  mixins: [MemberMixin],
  props: ['member_id'],
  methods: {
       
        async getDocumentTypes() {
            UtilityService.getSimpleApiData(this.accessToken, "document_types").then(
                response => this.document_types = response 
            );
        },
        async getMemberDocuments() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/documents")
            .then(
                (documents => {
                    this.$set(this, "documents", documents);
                    this.loading=false
                }).bind(this)
            );
        },
        async removeDocument(member_document_id) {
            this.working = true
            if(confirm("Are you sure you want to remove this document ?"))
            {
            
                var formdata = { 
                    _method: 'patch',
                    active : 0
                }; 

                MemberService.updateMemberDocument(this.accessToken, formdata, member_document_id)
                .then((response) => {
                    this.getMemberDocuments()
                    console.log(response)
                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            }
            else{
                this.working = false
                return false
            }
        },
        async saveMemberDocument() {
                this.working = true
                var formdata = { 
                     document_type_id : this.document_type_id,
                   member_id: this.member_id,
                   notes: this.notes,
                   received_date: this.received_date,
                   expiry_date: this.expiry_date
                    
                }; 

                MemberService.saveMemberDocument(this.accessToken, formdata)
                .then((response) => {
                    //this.$router.push("/bookings/new");
                    this.getMemberDocuments()
                    this.showAddForm = false
                    this.working = false
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
      
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberDocuments()
                this.getDocumentTypes()
            });
        
    },
    computed : {
       
    },
    watch: {
          
        }
}
</script>