<template>
    <div>
        <div class="notification is-info" v-if="loading === true">
            <p>Loading members ...</p>
        </div>

        <div class="notification is-warning" v-if="loading === false && members.length == 0">
            <p>There are no members to display</p>
        </div>

        <div class="notification is-danger" >
            <p>The following members have Access NIs that are expired</p>
        </div>
        
        <table class="table is-fullwidth is-striped is-hoverable" v-if="loading === false ">
            <thead>
                <tr>
                    <th>Surname</th>
                    <th>Forename</th>
                    <th>Grade</th>
                    <th>Expiry Date</th>
                    <th width="50"></th>
                </tr>
            </thead>
            <tbody>
                <tr >
                    <td>McGowan</td>
                    <td>Adam</td>
                    <td>Grade 5</td>
                    <td >30/1/2023</td>
                    <td><router-link to="/members/1"><button class="button is-small is-link" >View</button></router-link></td>
                </tr>
                
            </tbody>
        </table>

        <div class="notification is-warning" >
            <p>The following members have Access NIs that will expire soon</p>
        </div>
        
        <table class="table is-fullwidth is-striped is-hoverable" v-if="loading === false ">
            <thead>
                <tr>
                    <th>Surname</th>
                    <th>Forename</th>
                    <th>Grade</th>
                    <th>Expiry Date</th>
                    <th width="50"></th>
                </tr>
            </thead>
            <tbody>
                <tr >
                    <td>Small</td>
                    <td>Grace</td>
                    <td>Grade 4</td>
                    <td >2/3/2023</td>
                    <td><router-link to="/members/1"><button class="button is-small is-link" >View</button></router-link></td>
                </tr>
                
            </tbody>
        </table>
    </div>

</template>

<script>
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'Suppliers',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    members: [{'name':'David'}]
                   
            }
        },
        methods: {
            
            getJobs() {
                this.loading=true;
                StoneService.getJobs(this.accessToken)
                .then(
                    (jobs => {
                    
                    this.$set(this, "jobs", jobs);
                    this.loading=false;
                    }).bind(this)
                );
            },
            notWorking() {
                alert('Not yet working')
            }
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                //this.getJobs()
            });
        
        },
    }
</script>