<template>
    <div>
        <h3 class="title is-4">Grades</h3>
        
        <div v-if="loading == true" class="notification is-warning">
            Loading ...
        </div>

        <div v-if="loading==false">
            <div v-if="this.member_grades.length == 0" class="notification is-danger">
                This member has  not been linked to any grades.
            </div>

            <div v-if="this.member_grades.length > 0">
                <p>This member has been linked to the following grades.</p>
                <div class="buttons mt-3">
                    <button @click="showMemberGradeModal(member_grade.id)" v-for="member_grade in member_grades" :key="member_grade.id" class="button mb-3" :class="{'is-success': member_grade.valid==1, 'is-danger': member_grade.valid==0}">
                        {{  member_grade.name }}
                    </button>
                </div>
            </div>

            <hr />
            <h3 class="title is-4">Add a New Grade</h3>
            <div class="field select">
                <select v-model="grade_id" >
                    <option value="">Select grade</option>
                    <option v-for="row in filteredGrades" :key="row.id" :value="row.id">{{ row.name }}</option>
                </select>
            </div>
            <div class="field ">
                <div class="control">
                    <button class="button is-success" :disabled="working===true" @click="saveMemberGrade()">Save Grade</button>
                </div>
            </div>

            <div v-if="this.member_grades.length > 0" >
                <hr />
                <h3 class="title is-4 has-text-danger">REMOVE a Grade</h3>
                <div class="field select">
                    <select v-model="remove_grade_id" >
                        <option value="">Select grade to remove</option>
                        <option v-for="row in this.member_grades" :key="row.id" :value="row.id">{{ row.name }}</option>
                    </select>
                </div>
                <div class="field ">
                    <div class="control">
                        <button class="button is-danger" :disabled="working===true" @click="removeMemberGrade()">Remove Grade</button>
                    </div>
                </div>
            </div>
        </div>

        <MemberGradeModal :showModal="showModal" @closeModal="closeModal" :member_grade_id="member_grade_id"/>
    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

import MemberService from '@/services/MemberService.js';

import MemberGradeModal from '@/components/Generic/MemberGradeModal.vue';

export default {
    name: 'MemberGrades',
  data() {
    return {
      count: 0,
      member_grades: [],
      grades: [],
      grade_id: '',
      showModal: false,
      loading: true,
      working: false,
      member_grade_id: '',
      remove_grade_id: ''
    }
  },
  components: {
    MemberGradeModal
    },
  mixins: [MemberMixin],
  props: ['member_id'],
  methods: {
        showMemberGradeModal(id) {
            this.member_grade_id = id 
            this.showModal = true
        },
        async getMemberGrades() {
            this.loading = true;
            this.grade_id = '';
            this.remove_grade_id = '';

                MemberService.getMemberGrades(this.accessToken, this.member_id)
                .then(
                    (member_grades => {
                        this.$set(this, "member_grades", member_grades);
                        this.loading = false
                        this.working = false
                   
                    }).bind(this)
                );
            
        },
        async saveMemberGrade() {
			this.working = true
            var formdata = { 
                member_id : this.member_id,
                grade_id: this.grade_id
            }; 

            if(this.grade_id == '')
            {
                alert("Please select a grade to add");
                this.working = false
            }
            else
            {
                MemberService.saveMemberGrade(this.accessToken, formdata)
                .then((response) => {
                    
                    this.getMemberGrades();
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            }
        },
        async removeMemberGrade() {
			this.working = true
            var formdata = { 
                member_id : this.member_id,
                member_grade_id: this.remove_grade_id
            }; 
            
            if(this.remove_grade_id == '')
            {
                alert("Please select a grade to remove");
                this.working = false
            }
            else
            {
                if(confirm("Are you sure you want to remove this grade from the members profile ?"))
                {

                    MemberService.removeMemberGrade(this.accessToken, formdata)
                    .then((response) => {
                       
                        this.getMemberGrades();
                        console.log(response)
                        
                        }).catch((error) => {
                            if(error.response.status === 422)
                            {
                                console.log("Error:" + JSON.stringify(error.response.data));
                            }
                            alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                            this.working = false
                    });
                }
                else {
                    this.working = false
                    return false
                }
            }
        },
        
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberGrades();
                this.getGrades();
            });
        
        },

    computed: {
        filteredGrades() {
        // Get the IDs of member grades
        const memberGradeIds = this.member_grades.map(mg => mg.grade_id);

        // Filter grades to exclude any that are in member grades
        return this.grades.filter(grade => !memberGradeIds.includes(grade.id));
        }
    }
}
</script>