<template>
    <div class="home">
          <div class="box">	
              <form @submit.prevent="saveAlert">
                    <div class="field">
                          <label class="label">Method</label>
                          <div class="control">
                                <div class="buttons">

                                    <button type="button" class="button" :class="{'is-info' : email == true}" @click="email = !email">Email</button>
                                    <button type="button" class="button" :class="{'is-info' : sms === true}" @click="sms = !sms">SMS Text</button>
                                    <button type="button" class="button" :class="{'is-info' : app === true}" @click="app = !app">App Notification</button>
                                </div>
                              
                          </div>
                      </div>     
                      
                      <div class="field" >
                          <label class="label">Grades</label>
                          <div class="control buttons">
                            
                                <div class="button is-small" v-for="(grade, key) in grades" :id="grade.id" :key="key" :class="{ 'is-success': selected_grades.includes(grade.id) }" @click="toggleButton(grade.id)">{{ grade.name}}</div>
                            
                          </div>
                      </div>
                     
                
                      <div class="field" v-if="email==true">
                          <label class="label">Email Subject</label>
                          <div class="control">
                              <input class="input" type="text" v-model="email_subject"  required>
                             
                          </div>
                      </div>

                    <div class="field">
                          <label class="label">Alert Text</label>
                          <div class="control">
                              <textarea class="textarea" type="text" v-model="text" required  @input="countCharacters"></textarea>
                              <p :class="{'has-text-danger' : characterCount >= 160}"><em>Character Count: {{ characterCount }}</em></p>
                          </div>
                      </div>

                      

  
                     
                      <div class="field is-grouped" v-if="email ==1 || sms == 1 || app == 1">
                          <div class="control">
                              <button class="button is-success" :disabled="working===true">Save Alert</button>
                          </div>
                      </div>
                  </form>
            
          </div>
          
      </div>
  </template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';
   import MemberService from '@/services/MemberService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'AddAlert',
      mixins: [MemberMixin],
        data: function () {
            return {
                   loading : false,
                   working: false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    text: '',
                    email_subject: '',
                    email: true,
                    sms: false,
                    app:false,
                    grades: [],
                    selected_grades: [0],
                    characterCount: 0
                   
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
                this.getGrades();
            });
          
        },
        methods: {
            toggleButton(buttonId) {
                const index = this.selected_grades.indexOf(buttonId);
                if (index === -1) {
                    // Button not selected, add it to the selectedButtons array
                    this.selected_grades.push(buttonId);
                } else {
                    // Button already selected, remove it from the selectedButtons array
                    this.selected_grades.splice(index, 1);
                }
                
            },
            async saveAlert() {
                this.working = true

                if(this.selected_grades.length == 1 || this.selected_grades.length == 0) // it always contains the number 0
                {
                    alert("Please select some grades to send this alert to");
                    this.working = false
                }
                else{

                
                    var formdata = { 
                        text : this.text,
                        email_subject : this.email_subject,
                        email: this.email,
                        sms: this.sms,
                        app: this.app,
                        grades: this.selected_grades
                    }; 

                    MemberService.saveAlert(this.accessToken, formdata)
                    .then((response) => {
                        this.$router.push("/members/alerts");
                        console.log(response)
                        this.working = false
                        
                        }).catch((error) => {
                            
                            if(error.response.status == 422)
                            {
                                console.log("Error:" + JSON.stringify(error.response.data));
                                alert(error.response.data.message)
                            }
                            else{
                                alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                            }
                            this.working = false
                    });
                }
            },
            countCharacters() {
                this.characterCount = this.text.length;
                }
           
        }
    }
    </script>