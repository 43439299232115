<template>
    <div>
        <div class="notification is-warning" v-if="loading==true">Loading logs ... </div>
        <div class="notification is-info" v-if="loading==false && member_logs.length == 0">There are no logs to show for this member.</div>
        <table class="table is-fullwidth is-hoverable" v-if="loading==false && member_logs.length > 0">
            <thead>
                <tr>
                    <th>Date/Time</th>
                    <th>Description</th>
                    <th>User</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in member_logs" :key="row.id">
                    <td>{{ row.date}}</td>
                    <td>{{ row.log_text }}</td>
                    <td>{{ row.forename }} {{ row.surname }}</td>
                </tr>
                
            </tbody>
        </table>
    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

import UtilityService from '@/services/UtilityService.js';



export default {
    name: 'MemberLogs',
  data() {
    return {
      member_logs: [],
      loading: true,
      working: false,
      
    }
  },
  components: {
    
    },
  mixins: [MemberMixin],
  props: ['member_id'],
  methods: {
        showMemberGradeModal(id) {
            this.member_grade_id = id 
            this.showModal = true
        },
        async getMemberLogs() {
           
            UtilityService.getSimpleApiData(this.accessToken, "member_logs?member_id=" + this.member_id).then((response) => {
                console.log(response)
                    this.member_logs = response ;
                    this.loading=false
            });
            
        },
       
        
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberLogs();
                
            });
        
        },
}
</script>