<template>
    <div>
        <h3 class="title is-4">Upcoming Shifts (Confirmed)</h3>
        <div class="notification is-warning" v-if="loadingUpcoming == true">
            Loading bookings ...
        </div>
        <div v-if="loadingUpcoming == false" class="mb-4">
            <div class="notification is-link" v-if="upcoming_bookings.length == 0">
                There are no upcoming bookings for this member
            </div>

            <table class="table is-fullwidth is-hoverable" v-if="upcoming_bookings.length > 0">
                <thead>
                    <tr>
                        <th width="10">&nbsp;</th>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Location</th>
                        <th width="50">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in upcoming_bookings" :key="row.id">
                        <td>
                            <span class="icon pointer" @click="showLogsModal(row.logs)" >
                                <i class="fa fa-bars"></i>
                            </span>
                        </td>
                        <td><router-link :to="'/bookings/' + row.id" target="_blank">{{ row.id }}</router-link></td>
                        <td>{{ row.start_date}}</td>
                        <td>{{ row.start_time}} - {{ row.end_time}}</td>
                        <td>{{ row.client_name}} / {{ row.ward_name }}</td>
                        <td><button class="button is-small is-danger" style="display:none">Un-assign</button></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h3 class="title is-4">Assigned Shifts (Not Yet Confirmed)</h3>
        <div class="notification is-warning" v-if="loadingAssigned == true">
            Loading assigned bookings ...
        </div>
        <div v-if="loadingAssigned == false" class="mb-4">
            <div class="notification is-link" v-if="assigned_bookings.length == 0">
                There are no assigned bookings for this member
            </div>

            <table class="table is-fullwidth is-hoverable" v-if="assigned_bookings.length > 0">
                <thead>
                    <tr>
                        <th width="10">&nbsp;</th>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Location</th>
                        <th width="50">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in assigned_bookings" :key="row.id">
                        <td>
                            <span class="icon pointer" @click="showLogsModal(row.logs)" >
                                <i class="fa fa-bars"></i>
                            </span>
                        </td>
                        <td><router-link :to="'/bookings/' + row.id" target="_blank">{{ row.id }}</router-link></td>
                        <td>{{ row.start_date}}</td>
                        <td>{{ row.start_time}} - {{ row.end_time}}</td>
                        <td>{{ row.client_name}} / {{ row.ward_name }}</td>
                        <td><button class="button is-small is-danger" style="display:none">Un-assign</button></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h3 class="title is-4">Completed Shifts ({{ completed_bookings.length}})</h3>
        <div class="notification is-warning" v-if="loadingCompleted == true">
            Loading bookings ...
        </div>
        <div v-if="loadingCompleted == false" class="mb-4">
            <div class="notification is-link" v-if="completed_bookings.length == 0">
                There are no completed bookings for this member
            </div>

            <table class="table is-fullwidth is-hoverable" v-if="completed_bookings.length > 0">
                <thead>
                    <tr>
                        <th width="10">&nbsp;</th>
                        <td>ID</td>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Location</th>
                        <th>Timesheet</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in completed_bookings" :key="row.id">
                        <td>
                            <span class="icon pointer" @click="showLogsModal(row.id)" >
                                <i class="fa fa-bars"></i>
                            </span>
                        </td>
                        <td style="display:none"><router-link :to="'/bookings/' + row.id">{{ row.id }}</router-link></td>
                        <td><router-link :to="'/bookings/' + row.id" target="_blank">{{ row.id }}</router-link>
                            <div class="tag is-danger" v-if="row.booking_status_id==6 || row.booking_status_id==7 || row.booking_status_id==8">Cancelled</div>
                        </td>
                        <td>{{ row.start_date}}</td>
                        <td>
                            <span v-if="row.timesheet_processed == 1" class="has-text-success">
                                {{ row.actual_start_time}} - {{ row.actual_end_time}}
                            </span>
                            <span v-else>
                                {{ row.start_time}} - {{ row.end_time}}
                            </span>
                            
                        </td>
                        <td>{{ row.client_name}} / {{ row.ward_name }}</td>
                        <td>
                            <div class="tag is-success" v-if="row.timesheet_processed==1">Processed {{ row.week}}/{{row.year}}</div>
                            <div class="tag is-link" v-if="row.timesheet_submitted==1 && row.timesheet_processed==0">Submitted</div>
                            <div class="tag is-warning" v-if="row.timesheet_saved==1 && row.timesheet_submitted==0 && row.timesheet_processed==0">Saved</div>
                            <div class="tag is-danger" v-if="row.timesheet_saved==0 && row.timesheet_submitted==0 && row.timesheet_processed==0">Waiting For Timesheet</div>
                        </td>
                    
                    </tr>
                </tbody>
            </table>
        </div>

        <BookingLogsModel :showModal="showModal" @closeModal="closeModal" :booking_id="booking_id"/>

        <ViewBookingModal  :showModal="showViewBookingModal"  :booking_id="booking_id" @closeModal="closeViewBookingModal"/>
        
    </div>
</template>


<script>
import MemberMixin from '@/mixins/MemberMixin.js';
import MemberService from '@/services/MemberService.js';

import BookingLogsModel from '@/components/Bookings/BookingLogsModel.vue';
import ViewBookingModal from '@/components/Bookings/ViewBookingModal.vue';


export default {
    name: 'MemberBookings',
  data() {
    return {
      upcoming_bookings: [],
      completed_bookings: [],
      assigned_bookings: [],
      loadingUpcoming: true,
      loadingCompleted: true,
      loadingAssigned: true,
      working: false,
      showModal: false,
      showViewBookingModal : false,
      booking_id: ''
    }
  },
  components: {
        BookingLogsModel,
        ViewBookingModal
    },
  mixins: [MemberMixin],
  props: ['member_id'],
  methods: {
        showLogsModal(booking_id) {
            this.booking_id = booking_id
            this.showModal = true
        }, 
        showBookingModal(booking_id) {
            this.booking_id = booking_id
            this.showViewBookingModal = true
        },    
        async getUpcomingBookings() {
            this.loadingUpcoming = true;

            MemberService.getUpcomingBookings(this.accessToken, this.member_id)
            .then(
                (upcoming_bookings => {
                    this.$set(this, "upcoming_bookings", upcoming_bookings);
                    this.loadingUpcoming = false
                    this.working = false
                
                }).bind(this)
            );
            
        },
        async getCompletedBookings() {
            this.loadingCompleted = true;

            MemberService.getCompletedBookings(this.accessToken, this.member_id)
            .then(
                (completed_bookings => {
                    this.$set(this, "completed_bookings", completed_bookings);
                    this.loadingCompleted = false
                    this.working = false
                
                }).bind(this)
            );
            
        },
        async getAssignedBookings() {
            this.loadingAssigned = true;

            MemberService.getAssignedBookings(this.accessToken, this.member_id)
            .then(
                (assigned_bookings => {
                    this.$set(this, "assigned_bookings", assigned_bookings);
                    this.loadingAssigned = false
                    this.working = false
                
                }).bind(this)
            );
            
        },
    },
       
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getUpcomingBookings();
                this.getCompletedBookings();
                this.getAssignedBookings();
            });
        
        },
}
</script>