<template>
    <div>
        <h3 class="title is-4">Current Profile Image</h3>
        
        <div v-if="loading == true" class="notification is-warning">
            Loading ...
        </div>

        <div v-if="loading==false">
            <div v-if="member.member_image != '' && member.member_image != null && member.member_image != 'blankAvatar.png' ">
                <img :src="this.member_image" alt="" style="width:200px;" />
            </div>
            <div v-else>
                <div class="notification is-warning">No photo has been uploaded for this member yet.</div>
            </div>


            
            <form  @submit.prevent="uploadMemberImage" class="mt-4">
                <h3 class="title is-4">Upload New Image/Photo</h3>
                <div class="field ">
                    <div class="control">
                        <input type="file" class="input" @change="uploadFile" ref="file" />
                    </div>
                </div>

                <div class="field ">
                    <div class="control">
                        <button class="button is-success" :disabled="working===true" type="submit">Upload Photo</button>
                    </div>
                </div>

            </form>
           

           
        </div>
    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

import MemberService from '@/services/MemberService.js';

export default {
    name: 'MemberImage',
  data() {
    return {
      count: 0,
      loading: false,
      working: false,
      selectedFile: null
    }
  },
  mixins: [MemberMixin],
  props: ['member', 'member_image'],
  methods: {
    uploadFile(event) {
        this.selectedFile = event.target.files[0];
      },
        async uploadMemberImage() {
			this.working = true
            const formData = new FormData();
            formData.append('file', this.selectedFile);

            MemberService.uploadMemberImage(this.accessToken, formData, this.member.id)
            .then((response) => {
                this.$emit('reload-member')
                console.log(response)
                
                }).catch((error) => {
                    if(error.response.status === 422)
                    {
                        console.log("Error:" + JSON.stringify(error.response.data));
                    }
                    alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                    this.working = false
            });
        },
        
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                
            });
        
        },
}
</script>