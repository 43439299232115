<template>
    <div>
        <div class="is-pulled-right" style="display:none">
            <div class="button " :class="{'is-success' : include_archived == 1}" @click="toggleIncludeArchived">Include Archived</div>
        </div>
        
        
        <div class="buttons" >
            <div class="button is-small" v-for="(grade, key) in grades" :id="grade.id" :key="key" :class="{ 'is-success': selected_grades.includes(grade.id) }" @click="toggleButton(grade.id)">{{ grade.name}}</div>
        </div>

        <div class="notification is-warning" v-if="loading == true">
            Loading members ...
        </div>
        <div class="notification is-danger" v-if="loading == false  && members.length == 0">
            No members can be found matching your search criteria
        </div>

        <h4 class="title is-size-4">Cleared Members ({{ this.members.length}})</h4>

        <table class="table is-fullwidth is-hoverable is-size-7" v-if="loading == false && this.members.length > 0">
            <thead>
                <tr>
                    <th>Forename</th>
                    <th>Surname</th>
                    <th>Grade</th>
                    <th>Telephone</th>
                    <th>Email</th>
                    <th>DOB</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in this.members" :key="row.id" :class="{'has-background-danger-dark' : row.blocked==1, 'has-text-white' : row.blocked==1, 'has-background-warning' : row.archived==1}">
                    
                    <td><router-link :to="'/members/' + row.id" target="_blank">{{ row.forename }}</router-link></td>
                    <td><router-link :to="'/members/' + row.id" target="_blank">{{ row.surname}}</router-link></td>
                    <td><MemberGradeTags :member="row"/></td>
                    <td>{{ row.mobile }}</td>
                    <td>{{ row.email }}</td>
                    <td>{{ row.date_of_birth_formatted }}</td>
                    
                </tr>
                
                
            </tbody>
            
        </table>

       
    </div>
</template>

<script>
//import MemberService from '@/services/MemberService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
    
      name: 'MembersByGrade',
        mixins: [MemberMixin],
        components: {
            MemberGradeTags
        },
        data: function () {
            return {
                member_search: '',
                   members: [],
                   loading: false,
                   searchPerformed: false,
                   showModal: false,
                   order_by: 'surname ASC, forename ASC',
                   accessToken: '',
                   include_archived: 0,
                   grades: [],
                   selected_grades: [0]
            }
        },
        methods: {
            toggleButton(buttonId) {
                const index = this.selected_grades.indexOf(buttonId);
                if (index === -1) {
                    // Button not selected, add it to the selectedButtons array
                    this.selected_grades.push(buttonId);
                } else {
                    // Button already selected, remove it from the selectedButtons array
                    this.selected_grades.splice(index, 1);
                }
                this.getMembers(this.order_by, '', this.selected_grades);
            },
            toggleIncludeArchived()
            {
                if(this.include_archived == 1)
                {
                    this.include_archived = 0
                }
                else{
                    this.include_archived = 1
                }
                this.getMembers(this.order_by, this.include_archived);
            },
            resetForm()
            {
                this.member_search = '';
                localStorage.member_search = '';
                this.searchPerformed = false
                this.members = [];

                this.$refs.member_search.focus();
            },
            searchMembers()
            {
                //localStorage.member_search = this.member_search;
                //this.getMembers(this.order_by, this.include_archived);
            }
            
        },
        created() {
            this.getAccessToken()
                .then(() => { 
                    this.getMembers(this.order_by, '', this.selected_grades);
                    this.getGrades();
                });
        
        },
        mounted() {
            
        },
       
    }
</script>