<template>
    <div>
        <h3 class="title is-4">Member Actions</h3>
        
        <div class="buttons">
            <a :href="API_NAME + '/members/' + member.id + '/profile'" target="_blank" class="button  is-link">Print Member Profile</a>
            <a :href="API_NAME + '/members/' + member.id + '/id-badge'" target="_blank" class="button  is-link">Print ID Badge</a>
            <button class="button  is-link" @click="sendTestAppNotification()">Send Test App Notification</button>

        </div>
        <hr />
        <div v-if="member.archived==0">
            <form v-if="member.blocked==0" @submit.prevent="blockMember()">
                <div class="field">
                    <label class="label">Enter a reason for blocking the user</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="Block reason" v-model="block_reason" required>
                    </div>
                </div>
                <button class="button is-warning is-medium" type="submit" :disabled="working==true">Block Member</button>
            </form>

            <form v-if="member.blocked==1" @submit.prevent="unBlockMember()">
                <div class="field">
                    <label class="label">Enter a reason for un-blocking the user</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="Unblock reason" v-model="unblock_reason" required>
                    </div>
                </div>

                <button class="button is-success is-medium" type="submit" :disabled="working==true">Un-Block Member</button>
            </form>

            <hr />

            <form v-if="member.archived==0" @submit.prevent="archiveMember()"  >
                <div class="field">
                    <label class="label">Enter a reason for archiving the user</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="Archive reason" v-model="archive_reason" required>
                    </div>
                </div>

                <button class="button is-danger is-medium" type="submit" :disabled="working==true">Archive Member</button>
            </form>
        </div>

        <div v-if="member.archived==1">
            <div class="notification is-warning">Member has been archived. Please contact David to un-archive as it requires setting up a new login password.</div>
        </div>

        <hr />

       


        
    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

import MemberService from '@/services/MemberService.js';

export default {
    name: 'MemberActions',
  data() {
    return {
      count: 0,
      loading: false,
      working: false,
      unblock_reason: '',
      block_reason: '',
      archive_reason: '',
      accessToken: '',
      API_NAME: process.env.VUE_APP_API_NAME,
    }
  },
  mixins: [MemberMixin],
  props: ['member'],
  methods: {
        async sendTestAppNotification() {
            this.working = true
            var formdata = { 
                text: "Test message from Balmoral"
            };

            MemberService.sendTestAppNotification(this.accessToken, formdata, this.member.id)
            .then((response) => {
                console.log(response)
                alert("Notification Sent - This does not mean it was received. Check with the member to confirm receipt.")
                this.working=false;
                })
                .catch((error) => { 
                    if (error.response.status == 409) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.message)
                    } 
                    this.working=false;
                });
            
        },
        async blockMember() {
			this.working = true
            var formdata = { 
                block_reason: this.block_reason
            }; 

            MemberService.blockMember(this.accessToken, formdata, this.member.id)
            .then((response) => {
                console.log(response)
                this.$emit('reload-member')
                
                })
                .catch((error) => { 
                    if (error.response.status == 409) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.message)
                    } 
                    this.working=false;
                });
                
        },
        async unBlockMember() {
			this.working = true
            var formdata = { 
                unblock_reason: this.unblock_reason
            }; 

            MemberService.unBlockMember(this.accessToken, formdata, this.member.id)
            .then((response) => {
                console.log(response)
                this.$emit('reload-member')
                
                })
                .catch((error) => { 
                    if (error.response.status == 409) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.message)
                    } 
                    this.working=false;
                });
        },
        async archiveMember() {
			this.working = true
            var formdata = { 
                archive_reason: this.archive_reason
            }; 

            if(confirm('Are you sure you want to archive this member and remove access to the app ?'))
            {

                MemberService.archiveMember(this.accessToken, formdata, this.member.id)
                .then((response) => {
                    console.log(response)
                    this.$emit('reload-member')
                    
                    })
                    .catch((error) => { 
                        if (error.response.status == 409) {
                            alert(error.response.data.error)
                        } 
                        else{
                            alert(error.message)
                        } 
                        this.working=false;
                    }); 
            }
            else{
                this.working=false;
                return false;
            }  
        },
        
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                
            });
            
        },
}
</script>