<template>
    <div>
        
        <div class="notification is-warning" v-if="loading==true">Loading education ... </div>
        <div class="notification is-info" v-if="loading==false && education.length == 0">There are no education details to show. </div>
        <table class="table is-fullwidth is-hoverable" v-if="loading==false && education.length > 0">
            <thead>
                <tr>
                    <th>Establishment</th>
                    <th>Course</th>
                    <th>Year</th>
                    <th>Completion Date</th>
                    <th>Notes</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in education" :key="row.id">
                    <td>{{ row.establishment}}</td>
                    <td>{{ row.course}}</td>
                    <td>{{ row.year}}</td>
                    <td>{{ row.completion_date}}</td>
                    <td>{{ row.notes}}</td>
                    
                </tr>
                
            </tbody>
        </table>

    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberService from '@/services/MemberService.js';
import UtilityService from '@/services/UtilityService.js';

export default {
    name: 'Education',
  data() {
    return {
      loading: true,
      working: false,
      education: [],
      
    }
  },
  mixins: [MemberMixin],
  props: ['member_id'],
  methods: {
        
       
        async getMemberEducation() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/education")
            .then(
                (education => {
                    this.$set(this, "education", education);
                    this.loading=false
                }).bind(this)
            );
        },
      
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberEducation()
            });
        
    },
    computed : {
       
    }
}
</script>