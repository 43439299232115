<template>
    <div>
        <h3 class="title is-4">NMC Information</h3>
        
        <div v-if="loading == true" class="notification is-warning">
            Loading ...
        </div>

        <div v-if="loading==false">
            

            <div class="field">
                <label class="label">NMC Pin Number</label>
                <div class="control">
                    <input class="input" type="text" v-model="member.nmc_pin_number" >
                </div>
            </div>

            <div class="field">
                <label class="label">NMC Expiry Date</label>
                <div class="control">
                    <input class="input" type="date" v-model="member.nmc_expiry" >
                </div>
            </div>

            <div class="field">
                <label class="label">Last Checked On</label>
                <div class="control">
                    <input class="input" type="date" v-model="member.nmc_last_checked" disabled readonly>
                </div>
            </div>

            <div class="field ">
                <div class="control">
                    <button class="button is-success" :disabled="working===true" @click="updateNmc()">Update Details</button>
                </div>
            </div>
           

           
        </div>
    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

import MemberService from '@/services/MemberService.js';

export default {
    name: 'MemberNmc',
  data() {
    return {
      count: 0,
      loading: false,
      working: false
    }
  },
  mixins: [MemberMixin],
  props: ['member'],
  methods: {
        
        async updateNmc() {
			this.working = true
            var formdata = { 
                _method: 'patch',
                nmc_pin_number: this.member.nmc_pin_number,
                nmc_expiry: this.member.nmc_expiry,
            }; 

            MemberService.updateMember(this.accessToken, formdata, this.member.id)
            .then((response) => {
                this.$emit('reload-member')
                console.log(response)
                
                }).catch((error) => {
                    if(error.response.status === 422)
                    {
                        console.log("Error:" + JSON.stringify(error.response.data));
                    }
                    alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                    this.working = false
            });
        },
        
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                
            });
        
        },
}
</script>