<template>
    <div>
        <h2 class="title">Recent Alerts

            <router-link to="/members/add-alert"><button class="button is-success" >Create New Alert</button></router-link>
        </h2>
        
        <div class="notification is-warning" v-if="loading == true">
            Loading alerts ...
        </div>
        <div class="notification is-danger" v-if="loading == false && alerts.length == 0">
            No alerts can be found matching your search criteria
        </div>

        <table class="table is-fullwidth is-hoverable is-striped" v-if="loading == false && this.alerts.length > 0">
            <thead>
                <tr>
                    <th>Text</th>
                    <th>Date</th>
                    <th>User</th>
                    <th>Members</th>
                    <th>Email</th>
                    <th>SMS</th>
                    <th>App</th>
                    <th width="50"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in this.alerts" :key="row.id">
                    <td>{{ row.text}}</td>
                    <td>{{ row.created_at }}</td>
                    <td>{{ row.forename }} {{ row.surname }}</td>
                    <td>{{ row.members.length }}</td>
                    <td>
                        <span class="icon has-text-success" v-if="row.email == 1">
                            <i class="fa fa-check"></i>
                        </span>
                    </td>
                    <td>
                        <span class="icon has-text-success" v-if="row.sms == 1">
                            <i class="fa fa-check"></i>
                        </span>
                    </td>
                    <td>
                        <span class="icon has-text-success" v-if="row.app == 1">
                            <i class="fa fa-check"></i>
                        </span>
                    </td>
                    <td><router-link :to="'/alerts/' + row.id"><button class="button is-small is-link" v-if="1==2">View</button></router-link></td>
                </tr>
                
                
            </tbody>
            
        </table>

       
    </div>
</template>

<script>
import MemberService from '@/services/MemberService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'MembersDashboard',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   alerts: [],
                   loading: true,
                   searchPerformed: false
            }
        },
        methods: {
            getAlerts() {
                this.loading=true;
                MemberService.getAlerts(this.accessToken)
                .then(
                    (alerts => {
                    
                    this.$set(this, "alerts", alerts);
                    this.loading=false;
                    }).bind(this)
                );
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getAlerts();
            });
        
        },
    }
</script>