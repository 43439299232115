<template>
    <div>
        
        <div v-if="loading == true" class="notification is-warning">
            Loading ...
        </div>

        <div v-if="loading==false">
            
            <div class="columns">
                <div class="column">
                    <div class="field">
                        <label class="label">Date Of Consent</label>
                        <div class="control">
                            <input class="input" type="date" v-model="access_ni.consent_date" >
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Date Sent Off</label>
                        <div class="control">
                            <input class="input" type="date" v-model="access_ni.sent_off_date" >
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Received</label>
                        <div class="control">
                            <input class="input" type="date" v-model="access_ni.received_date" >
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Expiry</label>
                        <div class="control">
                            <input class="input" type="date" v-model="access_ni.calculated_expiry_date" readonly disabled>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Officer</label>
                        <div class="control">
                            <input class="input" type="text" v-model="access_ni.officer" >
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Reference</label>
                        <div class="control">
                            <input class="input" type="text" v-model="access_ni.reference" >
                        </div>
                    </div>
                </div>

                <div class="column">
                    <div class="field">
                        <label class="label">Notes</label>
                        <div class="control">
                            <textarea class="textarea" v-model="access_ni.notes" ></textarea>
                        </div>
                    </div>
                    <div class="field is-size-5">
                        <label class="checkbox">
                            <input type="checkbox"  value="1" v-model="access_ni.adult_checked">
                            Adult Checked
                        </label>
                    </div>
                    <div class="field is-size-5">
                        <label class="checkbox">
                            <input type="checkbox" value="1" v-model="access_ni.children_checked">
                            Children Checked
                        </label>
                    </div>
                    <div class="field is-size-5">
                        <label class="checkbox">
                            <input type="checkbox" value="1" v-model="access_ni.completed">
                            Completed
                        </label>
                    </div>
                </div>
            </div>
            

            
            <div class="field ">
                <div class="control">
                    <button class="button is-success" :disabled="working===true" @click="updateAccessNi()">Update Details</button>
                </div>
            </div>
           

           
        </div>
    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

import MemberService from '@/services/MemberService.js';
import UtilityService from '@/services/UtilityService.js';

export default {
    name: 'MemberAccessNi',
  data() {
    return {
      count: 0,
      loading: false,
      working: false,
      access_ni: []
    }
  },
  mixins: [MemberMixin],
  props: ['member_id'],
  methods: {
        async getMemberAccessNi() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/access_ni")
            .then(
                (access_ni => {
                    this.$set(this, "access_ni", access_ni);
                    this.loading=false
                }).bind(this)
            );
        },
        async updateAccessNi() {
			this.working = true
            var formdata = { 
                consent_date: this.access_ni.consent_date,
                sent_off_date: this.access_ni.sent_off_date,
                received_date: this.access_ni.received_date,
                officer: this.access_ni.officer,
                reference: this.access_ni.reference,
                notes: this.access_ni.notes,
                adult_checked: this.access_ni.adult_checked,
                children_checked: this.access_ni.children_checked,
                completed: this.access_ni.completed,
            }; 

            MemberService.updateMemberAccessNi(this.accessToken, formdata, this.member_id)
            .then((response) => {
                console.log(response)
                this.working = false

                this.getMemberAccessNi()
                
                }).catch((error) => {
                    if(error.response.status === 422)
                    {
                        console.log("Error:" + JSON.stringify(error.response.data));
                    }
                    alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                    this.working = false
            });
        },
        
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberAccessNi()
            });
        
        },
}
</script>