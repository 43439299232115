<template>
    <div class="modal" :class="{'is-active': showModal==true}">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Booking Logs</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body">
                <div class="notification is-warning" v-if="loading==true">
                    Loading logs ...
                </div>
                <table class="table is-striped is-hoverable is-fullwidth" v-if="loading==false">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Text</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in booking_logs" :key="row.id">
                            <td>{{  row.date }}</td>
                            <td>{{ row.text}}</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <footer class="modal-card-foot">
                <button class="button" @click="closeModal()">Close</button>
            </footer>
        </div>
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';
//import BookingService from '@/services/BookingService.js';

export default {
    
      name: 'PendingBookings',
        mixins: [MemberMixin],
        props: ['showModal', 'booking_id'],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: true,
                   searchPerformed: false,
                   assigned:false,
                   alertText: '',
                   booking_logs: [],
                   selected_booking: [],
            }
        },
        methods: {
            async getBookingLogs() {
                if(this.booking_id > 0)
                {
                    this.loading=true
                    this.booking_logs = []
                    UtilityService.getSimpleApiData(this.accessToken, "booking_logs?booking_id=" + this.booking_id)
                    .then(
                        (booking_logs => {
                            this.$set(this, "booking_logs", booking_logs);
                            this.loading=false
                        }).bind(this)
                    );
                }
            },
            async closeModal() {
                this.$emit('closeModal')
            },
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getBookingLogs();
                //this.getMembers();
            });
        
        },
        watch: {
            booking_id: function(){
                this.getBookingLogs();
            }
        }
    }
</script>