<template>
    <div>
        
        <div class="notification is-warning" v-if="loading==true">Loading health questionaire ... </div>
        <div class="notification is-info" v-if="loading==false && health_categories.length == 0">There are no health details to show. </div>
        <table class="table  is-hoverable is-fullwidth" >
            <tbody>
            <template v-for=" row in health_categories " >
                <tr :key="'title' + row.id">
                    <th colspan="2">{{ row.name}}</th>
                </tr>
                <tr v-for="question in row.questions " :key="'question' + question.id">
                    <td>{{ question.name}}
                        <p v-if="question.notes != ''"><strong>{{ question.notes}}</strong></p>
                    </td>
                    <td width="25%"><button class="button is-small" :class="{'is-danger' : question.answer == 1}" :disabled="working==true" >Yes</button>
                        <button class="button is-small " :class="{'is-success' : question.answer == 0}" :disabled="working==true" >No</button></td>
                </tr>
                
            </template>
            
        </tbody>
        </table>

    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberService from '@/services/MemberService.js';
import UtilityService from '@/services/UtilityService.js';

export default {
    name: 'Education',
  data() {
    return {
      loading: true,
      working: false,
      health_categories: [],
      
    }
  },
  mixins: [MemberMixin],
  props: ['member_id'],
  methods: {
        
       
        async getMemberHealth() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/health")
            .then(
                (health_categories => {
                    this.$set(this, "health_categories", health_categories);
                    this.loading=false
                }).bind(this)
            );
        },
      
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberHealth()
            });
        
    },
    computed : {
       
    }
}
</script>