<template>
    <div>
        <h2 class="title">HCPC Check</h2>

       
        <div class="notification is-warning" v-if="loading == true">
            Loading members ...
        </div>
        <div class="notification is-danger" v-if="loading == false && members.length == 0">
            No active members can be found 
        </div>

        <table class="table is-fullwidth is-hoverable" v-if="loading == false && this.members.length > 0">
            <thead>
                <tr>
                    <th width="50"></th>
                    <th>Forename</th>
                    <th>Surname</th>
                    <th>Expiry Date</th>
                    <th>Last Checked</th>
                    <th>New Date</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in this.members" :key="row.id" :class="{'has-background-danger-dark' : row.blocked==1, 'has-text-white' : row.blocked==1}">
                    <td><router-link :to="'/members/' + row.id" target="_blank"><button class="button is-small is-link" >View</button></router-link></td>
                    <td>{{ row.forename }}</td>
                    <td>{{ row.surname}}</td>
                    <td>{{ row.hcpc_expiry }}</td>
                    <td>{{ row.hcpc_last_checked }}</td>
                    <td><input type="date" class="input" v-model="row.hcpc_new_date"/></td>
                    <td><button class="button is-small is-success" @click="updateHcpc(row.id, row.hcpc_new_date)">Update</button></td>
                </tr>
                
                
            </tbody>
            
        </table>

       
    </div>
</template>

<script>
import MemberService from '@/services/MemberService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
    
      name: 'HcpcCheck',
        mixins: [MemberMixin],
        components: {
            //MemberGradeTags
        },
        data: function () {
            return {
                   search: '',
                   members: [],
                   loading: false,
                  
                   showModal: false,
                   order_by: "hcpc_last_checked ASC"
            }
        },
        methods: {
            async updateHcpc(member_id, hcpc_last_checked) {
                this.working = true
                var formdata = { 
                    _method: 'patch',
                    hcpc_last_checked: hcpc_last_checked,
                    
                }; 

                MemberService.updateMember(this.accessToken, formdata, member_id)
                .then((response) => {
                    this.getHcpcMembers(this.order_by)
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getHcpcMembers(this.order_by)
            });
        
        },
    }
</script>