<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading availability ...
        </div>
        
        <div v-if="loading==false">
            <div class="columns is-size-7" style="" v-if="1==1"> <!-- Removed deceased==0 && cleared==1 && archived==0-->
                <div class="column"><input type="date" class="input" v-model="date"/></div>
                <div class="column mt-2 " v-for="row in time_periods" :key="row.id">
                    <label class="checkbox">
                    <input type="checkbox" v-model="selected_time_periods" :true-value="[]" :value="row.id" class="mr-2">
                    <strong>{{ row.name }}</strong> ({{ row.short_from }} - {{  row.short_to }})
                    </label>
                </div>
                <div class="column">
                    <button type="button" class="button is-success" @click="saveMemberAvailability()" :disabled="working==true || this.loading==true">Add Availability</button>
                </div>
            </div>
                
            <table class="table is-fullwidth is-hoverable" >
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Time Period</th>
                        <th width="50">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in availability" :key="row.id">
                        <td>
                            {{ row.date }}
                        </td>
                        <td>{{ row.times_list }}</td>
                        <td><button class="button is-danger is-small" @click="deleteMemberAvailability(row.date)">Delete</button></td>
                    </tr>
                    
                </tbody>
            </table>

           
        </div>

    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberService from '@/services/MemberService.js';

import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'MemberTraining',
        mixins: [MemberMixin],
        components: {
            
        },
        data: function () {
            return {
                   search: '',
                   availability: [],
                   loading: true,
                   working: false,
                   member_id : this.$route.params.id,
                   date: new Date().toJSON().slice(0,10),
                   time_periods: [],
                   selected_time_periods: []
                   
            }
        },
        methods: {
            
            async getMemberAvailability() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/availability?future_only=1")
                .then(
                    (availability => {
                        this.$set(this, "availability", availability);
                        this.loading=false
                    }).bind(this)
                );
            },
            async saveMemberAvailability() {
                this.working = true
                var formdata = { 
                    member_id : this.member_id,
                    date: this.date,
                    time_periods: this.selected_time_periods
                }; 

                MemberService.saveMemberAvailability(this.accessToken, formdata)
                .then((response) => {
                    this.getMemberAvailability();
                    console.log(response)
                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
            async deleteMemberAvailability(date_to_delete) {
                this.working = true
                var formdata = { 
                    member_id : this.member_id,
                    date: date_to_delete
                }; 

                MemberService.deleteMemberAvailability(this.accessToken, formdata)
                .then((response) => {
                    this.getMemberAvailability();
                    console.log(response)
                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberAvailability();
                this.getTimePeriods();
            });
        
        },
        computed : {
           
        },
    }
</script>