<template>
    <div>
        
        <div v-if="loading == true" class="notification is-warning">
            Loading ...
        </div>

        <div v-if="loading==false">
            <div class="columns">
                <div class="field column">
                          <label class="label">Title</label>
                          <div class="select is-fullwidth ">
                            <select  v-model="member.title" required class="select input">
                                <option>Select title</option>
                                <option v-for="row in titles" :key="row.name" :value="row.name">{{  row.name  }}</option>
                            </select>
                            </div>
                            
                      </div>
                <div class="field column">
                    <label class="label">Forename</label>
                    <div class="control">
                        <input class="input" type="text" v-model="member.forename">
                    </div>
                </div>
                <div class="field column">
                    <label class="label">Middle name</label>
                    <div class="control">
                        <input class="input" type="text" v-model="member.middlename" >
                    </div>
                </div>
                <div class="field column">
                    <label class="label">Surname</label>
                    <div class="control">
                        <input class="input" type="text" v-model="member.surname" >
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="field column">
                          <label class="label">Gender</label>
                          <div class="select is-fullwidth">
                            <select  v-model="member.gender_id" required class="select " >
                                <option>Select gender</option>
                                <option v-for="row in genders" :key="row.id" :value="row.id">{{  row.name  }}</option>
                            </select>
                            </div>
                            
                      </div> 
                <div class="field column">
                    <label class="label">Previous Surname(s)</label>
                    <div class="control">
                        <input class="input" type="text" v-model="member.maidenname">
                    </div>
                </div>
                <div class="field column">
                    <label class="label">Date of Birth</label>
                    <div class="control">
                        <input class="input" type="date" v-model="member.date_of_birth" >
                    </div>
                </div>
                <div class="field column">
                          <label class="label">Nationality</label>
                          <div class="select is-fullwidth">
                            <select  v-model="member.nationality_id" required class="select " >
                                <option>Select nationality</option>
                                <option v-for="row in nationalities" :key="row.id" :value="row.id">{{  row.name  }}</option>
                            </select>
                            </div>
                            
                      </div> 
            </div>

            <div class="columns">
                <div class="field column">
                    <label class="label">Email Address</label>
                    <div class="control">
                        <input class="input" type="email" v-model="member.email" readonly disabled>
                    </div>
                </div>
                <div class="field column">
                    <label class="label">Mobile</label>
                    <div class="control">
                        <input class="input" type="text" v-model="member.mobile" >
                    </div>
                </div>
                <div class="field column">
                    <label class="label">Home Telephone</label>
                    <div class="control">
                        <input class="input" type="text" v-model="member.home_tel" >
                    </div>
                </div>
                <div class="field column">
                    <label class="label">National Insurance</label>
                    <div class="control">
                        <input class="input" type="text" v-model="member.national_ins" >
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="field column">
                    <label class="label">Address</label>
                    <div class="control">
                        <input class="input" type="email" v-model="member.address1" >
                    </div>
                </div>
                <div class="field column">
                    <label class="label">County</label>
                    <div class="control">
                        <input class="input" type="text" v-model="member.address2" >
                    </div>
                </div>
                <div class="field column">
                    <label class="label">City</label>
                    <div class="control">
                        <input class="input" type="text" v-model="member.address3" >
                    </div>
                </div>
                <div class="field column">
                    <label class="label">Postcode</label>
                    <div class="control">
                        <input class="input" type="text" v-model="member.postcode" >
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="field column">
                    <label class="label">Started Working With Us</label>
                    <div class="control">
                        <input class="input" type="date" v-model="member.approved_date" readonly disabled >
                    </div>
                </div>
                <div class="field column">
                    <label class="label">First Worked In Trust</label>
                    <div class="control">
                        <input class="input" type="date" v-model="member.first_worked_in_trust" >
                    </div>
                </div>
                <div class="field column">
                    <label class="label">20 Hours Check</label>
                    <div class="control">
                        <input class="check" type="checkbox" v-model="member.twenty_hours_check" value="1" >
                    </div>

                </div>
                <div class="field column"></div>
            </div>

            <h4 class="title is-size-4">Visa Information</h4>
            <div class="columns">
                <div class="field column">
                    <label class="label">Visa Required ?</label>
                    <div class="control">
                        <input class="check" type="checkbox" v-model="member.visa_required" value="1" >
                    </div>
                </div>
                <div class="field column">
                    <label class="label">Pre Settlement</label>
                    <div class="control">
                        <input class="check" type="checkbox" v-model="member.pre_settlement" value="1" >
                    </div>
                </div>
                <div class="field column">
                    <label class="label">Full Settlement</label>
                    <div class="control">
                        <input class="check" type="checkbox" v-model="member.full_settlement" value="1" >
                    </div>
                </div>
                <div class="field column">
                    <label class="label">Leave To Remain</label>
                    <div class="control">
                        <input class="check" type="checkbox" v-model="member.leave_to_remain" value="1" >
                    </div>
                </div>
                
                
            </div>
            <div class="columns" v-if="member.visa_required==1">
                <div class="field column">
                   
                        <label class="label">Visa Expiry Date</label>
                        <div class="control">
                            <input class="input" type="date" v-model="member.visa_expiry_date" >
                        </div>
                    
                </div>
                <div class="field column">
                    <label class="label">Visa Type</label>
                          <div class="select is-fullwidth">
                            <select  v-model="member.visa_type"  class="select " >
                                <option>Select Visa Type</option>
                                <option value="work">Work</option>
                                <option value="study">Study</option>
                                <option value="spouse">Spouse</option>
                                <option value="ancestry">Ancestry</option>
                                <option value="dependant">Dependant</option>
                                
                            </select>
                    
                    </div>
                </div>
                <div class="field column">
                   
                        <label class="label">Share Code</label>
                        <div class="control">
                            <input class="input" type="text" v-model="member.visa_share_code" >
                        </div>
                    
                </div>
                <div class="field column">
                   
                        <label class="label">Image Stored On Server</label>
                        <div class="control">
                            <input class="check" type="checkbox" v-model="member.visa_image_stored" value="1" >
                        </div>
                    
                </div>
            </div>
            <div class="columns" v-if="member.visa_required==1">
               
               
                <div class="field column ">
                    <label class="label">Visa Status</label>
                    <div class="control">
                        <input class="input" type="text" v-model="member.visa_status" >
                    </div>
                </div>
                <div class="field column">
                    <label class="label">CoS</label>
                    <div class="control">
                        <input class="check" type="checkbox" v-model="member.cos" value="1" >
                    </div>
                </div>
                <div class="field column">
                    <div v-if="member.cos == 1">
                        <label class="label">CoS Expiry</label>
                        <div class="control">
                            <input class="input" type="date" v-model="member.cos_expiry" >
                        </div>
                    </div>
                </div>
                <div class="field column">
                   
                </div>
               
                
            </div>
            
            <div class="field ">
                <div class="control">
                    <button class="button is-success" :disabled="working===true" @click="updatePersonal()">Update Details</button>
                </div>
            </div>

           
           
        </div>
    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

import MemberService from '@/services/MemberService.js';

export default {
    name: 'MemberPersonal',
  data() {
    return {
      count: 0,
      loading: false,
      working: false,
      genders: [],
      nationalities: [],
      titles : [{'name':'Mr'},{'name':'Miss'},{'name':'Mrs'},{'name':'Ms'},{'name':'Dr'},{'name':'Sister'}],
    }
  },
  mixins: [MemberMixin],
  props: ['member'],
  methods: {
        
        async updatePersonal() {
			this.working = true
            var formdata = { 
                _method: 'patch',
                title: this.member.title,
                forename: this.member.forename,
                middlename: this.member.middlename,
                surname: this.member.surname,
                maidenname: this.member.maidenname,
                gender_id : this.member.gender_id,
                date_of_birth : this.member.date_of_birth,
                mobile: this.member.mobile,
                home_tel: this.member.home_tel,
                address1: this.member.address1,
                address2: this.member.address2,
                address3: this.member.address3,
                postcode: this.member.postcode,
                national_ins: this.member.national_ins,
                nationality_id: this.member.nationality_id,
                first_worked_in_trust: this.member.first_worked_in_trust,
                visa_required : this.member.visa_required,
                visa_expiry_date : this.member.visa_expiry_date,
                visa_type : this.member.visa_type,
                visa_image_stored : this.member.visa_image_stored,
                pre_settlement : this.member.pre_settlement,
                full_settlement : this.member.full_settlement,
                visa_share_code : this.member.visa_share_code,
                visa_status : this.member.visa_status,
                leave_to_remain : this.member.leave_to_remain,
                cos : this.member.cos,
                cos_expiry : this.member.cos_expiry,
                twenty_hours_check: this.member.twenty_hours_check
            }; 

            MemberService.updateMember(this.accessToken, formdata, this.member.id)
            .then((response) => {
                this.$emit('reload-member')
                console.log(response)
                alert("Personal Details have now been updated")
                this.working=false
                
                }).catch((error) => {
                    if(error.response.status === 422)
                    {
                        console.log("Error:" + JSON.stringify(error.response.data));
                    }
                    alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                    this.working = false
            });
        },
        
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getGenders();
                this.getNationalities();
            });
        
        },
}
</script>