<template>
    <div>
        
        
        <div class="is-pulled-right">
            <input type="text" class="input" v-model="search" placeholder="Search ..."/>
        </div>

        <div class="is-pulled-right mr-4">
            <button class="button is-success" @click="openNoteModal()">Add New Note</button>
        </div>
        <div>
        <h3 class="title is-4">Member Notes and History
            <button class="button is-warning is-small" @click="getMemberNotes()">Reload</button>
        </h3>
        </div>
        
        <div class="notification is-warning" v-if="loading==true">Loading notes ... </div>
        <div class="notification is-info" v-if="loading==false && filtered_member_notes.length == 0">There are no notes to show. Try clearing your search.</div>
        <p><i>Please note that this page is not currently showing notes that start with "Shift Opportunity"</i></p>
        <table class="table is-fullwidth is-hoverable is-size-7" v-if="loading==false && filtered_member_notes.length > 0">
            <thead>
                <tr>
                    <th>Date/Time</th>
                    <th>Booking</th>
                    <th>User</th>
                    <th>Type</th>
                    <th style="display:none">Reason</th>
                    <th>Subject</th>
                    <th width="55%">Body</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filtered_member_notes" :key="row.id">
                    <td ><span :title="row.date_time">{{ row.date_time_formatted}}</span></td>
                    
                    <td >
                        <span v-if="row.booking_id > 0">{{ row.booking_id}}</span>
                    </td>
                    <td>{{ row.forename}} {{row.surname}}</td>
                    <td>{{ row.note_type_name}}</td>
                    <td  style="display:none">{{ row.reason}}</td>
                    <td>{{ row.subject}}</td>
                    <td v-html="row.body"></td>
                </tr>
                
            </tbody>
        </table>

        <NoteModal :member_id="this.member_id" :showNoteModal="showNoteModal" @close="closeNoteModal"/>

    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberService from '@/services/MemberService.js';
import UtilityService from '@/services/UtilityService.js';

import NoteModal from './NoteModal.vue'

export default {
    name: 'MemberNotes',
    components: {
        NoteModal
    },
  data() {
    return {
      count: 0,
      loading: true,
      working: false,
      member_notes: [],
      today: this.getTodaysDate(),
      today_plus_year: this.getTodaysDatePlusYear(),
      search: '',
      showNoteModal: false
    }
  },
  mixins: [MemberMixin],
  props: ['member_id'],
  methods: {
        openNoteModal() {
                this.showNoteModal = true
            },
       
        async getMemberNotes() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/notes?exclude_shift_opportunities=1")
            .then(
                (member_notes => {
                    this.$set(this, "member_notes", member_notes);
                    this.loading=false
                }).bind(this)
            );
        },
      
        getTodaysDate() {
            
            var d = new Date();
            
            d.setDate(d.getDate());
            return d.toISOString().slice(0,10);
            
        },

        getTodaysDatePlusYear() {
            
            var d = new Date();
            
            d.setFullYear(d.getFullYear() + 1);
            return d.toISOString().slice(0,10);
            
        },
        
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberNotes()
            });
        
    },
    computed : {
        filtered_member_notes: function () {
        
            let filtered_member_notes =  this.member_notes.filter(function(u) {
                if(  u.active == 1) 
                {
                return true
                }
                else
                {
                return false
                }
            })

            if(this.search != '' && this.search != null)
            {
                filtered_member_notes = filtered_member_notes.filter(
                    row => (row.subject != null && row.subject.toLowerCase().includes(this.search.toLowerCase() ) ) 
                    || (row.body != null && row.body.toLowerCase().includes(this.search.toLowerCase() )) 
                    || (row.reason != null && row.reason.toLowerCase().includes(this.search.toLowerCase() ) )
                )
            }
            return filtered_member_notes
        },
    }
}
</script>