<template>
    <div>
        <h2 class="title">HCPC Report</h2>

       
        <div class="notification is-warning" v-if="loading == true">
            Loading members ...
        </div>
        <div class="notification is-danger" v-if="loading == false && members.length == 0">
            No active members can be found that require HCPC
        </div>

        <table class="table is-fullwidth is-hoverable" v-if="loading == false && this.members.length > 0">
            <thead>
                <tr>
                    <th width="50"></th>
                    <th>Forename</th>
                    <th>Surname</th>
                    <th>Date of Birth</th>
                    <th>HCPC Number</th>
                    <th>Reg. Date</th>
                    <th>Expiry Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in this.members" :key="row.id" :class="{'has-background-danger-dark' : row.blocked==1, 'has-text-white' : row.blocked==1}">
                    <td><router-link :to="'/members/' + row.id" target="_blank"><button class="button is-small is-link" >View</button></router-link></td>
                    <td>{{ row.forename }}</td>
                    <td>{{ row.surname}}</td>
                    <td>{{ row.date_of_birth_formatted}}</td>
                    <td>{{ row.hcpc_pin_number }}</td>
                    <td>{{ row.hcpc_registration_date_formatted }}</td>
                    <td>{{ row.hcpc_expiry_formatted }}</td>
                    <td>
                        <span class="tag is-danger" v-if="checkDate(row.hcpc_expiry)">Expired</span>

                    </td>
                </tr>
                
                
            </tbody>
            
        </table>

       
    </div>
</template>

<script>
//import MemberService from '@/services/MemberService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
    
      name: 'MembersDashboard',
        mixins: [MemberMixin],
        components: {
            //MemberGradeTags
        },
        data: function () {
            return {
                   search: '',
                   members: [],
                   loading: false,
                  
                   showModal: false,
                   order_by: "hcpc_expiry ASC"
            }
        },
        methods: {
            checkDate(checkDate) {
                if(checkDate != '' && checkDate != null)
                {
                    const sqlDateParts = checkDate.split('-');
                    
                    const sqlTimestamp = new Date(sqlDateParts[0], sqlDateParts[1] - 1, sqlDateParts[2]);
                    const targetTimestamp = new Date();

                    let isBeforeTargetDate = sqlTimestamp < targetTimestamp;

                    return isBeforeTargetDate
                }
                else {
                    return false
                }
                
            },
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getHcpcMembers(this.order_by)
            });
        
        },
    }
</script>