<template>
    <div>
        <h2 class="title is-size-4">Further Education</h2>
        <div class="notification is-warning" v-if="loading==true">Loading education ... </div>
        <div class="notification is-info" v-if="loading==false && education.length == 0">There are no education details to show. </div>
        <table class="table is-fullwidth is-hoverable" v-if="loading==false && education.length > 0">
            <thead>
                <tr>
                    <th>Establishment</th>
                    <th>Course</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in education" :key="row.id">
                    <td>{{ row.establishment}}</td>
                    <td>{{ row.course}}</td>
                    <td>{{ row.start_date_display}}</td>
                    <td>{{ row.end_date_display}}</td>
                    
                </tr>
                
            </tbody>
        </table>

        <h2 class="title is-size-4">Employment</h2>
        <div class="notification is-warning" v-if="loading_employment==true">Loading employment ... </div>
        <div class="notification is-info" v-if="loading_employment==false && employment.length == 0">There are no employment details to show. </div>
        <table class="table is-fullwidth is-hoverable" v-if="loading_employment==false && employment.length > 0">
            <thead>
                <tr>
                    <th>Employer</th>
                    <th>Job Title</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Reason For Leaving</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in employment" :key="row.id">
                    <td>{{ row.employer_name}}</td>
                    <td>{{ row.job_title}}</td>
                    <td>{{ row.start_date_display}}</td>
                    <td>{{ row.end_date_display}}</td>
                    <td>{{ row.reason_for_leaving}}</td>
                </tr>
                
            </tbody>
        </table>

    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberService from '@/services/MemberService.js';
import UtilityService from '@/services/UtilityService.js';

export default {
    name: 'EmploymentHistory',
  data() {
    return {
      loading: true,
      working: false,
      loading_employment: false,
      education: [],
      employment: []
    }
  },
  mixins: [MemberMixin],
  props: ['member_id'],
  methods: {
        
       
        async getMemberEducation() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/education")
            .then(
                (education => {
                    this.$set(this, "education", education);
                    this.loading=false
                }).bind(this)
            );
        },

        async getMemberWorkExperience() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/work_experience")
            .then(
                (employment => {
                    this.$set(this, "employment", employment);
                    this.loading=false
                }).bind(this)
            );
        },
      
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberEducation()
                this.getMemberWorkExperience();
            });
        
    },
    computed : {
       
    }
}
</script>