<template>
    <div class="home">
          <div class="box">	
              <form @submit.prevent="saveMember">
                <div class="field">
                    <label class="label">Forename</label>
                    <div class="control">
                        <input class="input" type="text" v-model="forename"  required autofocus>
                        
                    </div>
                </div>    
                <div class="field">
                    <label class="label">Surname</label>
                    <div class="control">
                        <input class="input" type="text" v-model="surname"  required>
                        
                    </div>
                </div>
                <div class="field">
                    <label class="label">Date of Birth</label>
                    <div class="control">
                        <input class="input" type="date" v-model="date_of_birth" required >
                    </div>
                </div> 
                <div class="field">
                    <label class="label">Email</label>
                    <div class="control">
                        <input class="input" type="email" v-model="email"  required>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Mobile Number</label>
                    <div class="control">
                        <input class="input" type="text" v-model="mobile"  >
                    </div>
                </div>    
                
                <div class="field">
                    <label class="label">Initial Grade</label>
                    <div class="control">
                        <select class="input" v-model="grade_id" >
                            <option value="">Please select ...</option>
                            <option :value="row.id" v-for="row in grades" :key="row.id">{{ row.name }}</option>
                        </select>
                    </div>
                </div>  
                
                <div class="field">
                    <label class="label">Auth0</label>
                    <div class="control">
                        <input class="input" type="text" v-model="auth0"  >
                    </div>
                </div>  

                <div class="field is-grouped">
                    <div class="control">
                        <button class="button is-success" :disabled="working===true">Save Member</button>
                    </div>
                </div>
            </form>
            
          </div>
          
      </div>
  </template>

<script>
   import MemberService from '@/services/MemberService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    import MemberMixin from '@/mixins/MemberMixin.js';

    export default {
      name: 'AddAlert',
        mixins: [MemberMixin],
        data: function () {
            return {
                   loading : false,
                   working: false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    grades: [],
                    forename: '',
                    surname: '',
                    email: '',
                    date_of_birth: '',
                    mobile: '',
                    grade_id: '',
                    auth0 : ''
                   
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
              this.getGrades();
            });
          
        },
        methods: {
            
            async saveMember() {
                this.working = true;

                var formdata = { 
                    forename : this.forename,
                    surname: this.surname,
                    email: this.email,
                    date_of_birth: this.date_of_birth,
                    mobile: this.mobile,
                    grade_id: this.grade_id,
                    auth0: this.auth0,
                }; 

                MemberService.saveMember(this.accessToken, formdata)
                .then((response) => {
                    this.working = false
                    this.$router.push("/members/" + response.id);
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
           
        }
    }
    </script>