<template>
    

  <div class="modal" :class="{'is-active': showModal==true}">
      <div class="modal-background"></div>
      <div class="modal-card" >
          <header class="modal-card-head">
              <p class="modal-card-title">Add/Update Training</p>
              <button class="delete" aria-label="close" @click="closeModal()"></button>
          </header>
          <section class="modal-card-body " >
              
              <div class="field">
                  <label class="label">Course</label>
                  <div class="control">
                      <select class="input" v-model="course_id" >
                        <option value="">Please select ...</option>
                        <option :value="row.id" v-for="row in courses" :key="row.id">{{ row.name }}</option>
                    </select>
                  </div>
              </div>

              <div class="field">
                  <label class="label">Date Completed</label>
                  <div class="control">
                      <input class="input" type="date" v-model="date"  >
                      
                  </div>
              </div>
          </section>
          
          <footer class="modal-card-foot" >
          <button class="button is-success" @click="saveMemberCourse()" :disabled="working==true">Save</button>
          <button class="button" @click="closeModal()" :disabled="working==true">Cancel</button>
          </footer>
      </div>
      
  </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberService from '@/services/MemberService.js';

import MemberMixin from '@/mixins/MemberMixin.js';

export default {
  data() {
    return {
      count: 0,
      working: false,
      courses: [],
      date: this.getTodaysDate(),
    }
  },
  mixins: [MemberMixin],
  props: [ 'showModal', 'member_id', 'course_id'],
  methods : {
    closeModal() {
        this.$emit('closeModal')
    },
    async getCourses() {
        UtilityService.getSimpleApiData(this.accessToken, "courses").then(
            response => this.courses = response 
        );
                
    },
    async saveMemberCourse() {
          this.working = true
          var formdata = { 
              course_id: this.course_id,
              member_id : this.member_id,
              date: this.date
              
          }; 

          MemberService.saveMemberCourse(this.accessToken, formdata)
          .then((response) => {
              this.closeModal();
              console.log(response)
              this.working = false
              
              }).catch((error) => {
                  if(error.response.status === 422)
                  {
                      console.log("Error:" + JSON.stringify(error.response.data));
                  }
                  alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                  this.working = false
          });
      },
  },
  created() {
        this.getAccessToken()
        .then(() => { 
            this.getCourses();
            
        });
    
    },
}
</script>

