<template>
    <div>
        <h2 class="title">20 Hours Report</h2>

       <button class="button " :disabled="loading==true" @click="update20Hours()" :class="{'is-success' : show_all_members == 1}">Show All Members</button>

        <div class="notification is-warning" v-if="loading == true">
            Loading members ...
        </div>
        <div class="notification is-danger" v-if="loading == false && filtered_members.length == 0">
            No members can be found
        </div>

        <table class="table is-fullwidth is-hoverable" v-if="loading == false && this.filtered_members.length > 0">
            <thead>
                <tr>
                    <th width="50"></th>
                    <th>Forename</th>
                    <th>Surname</th>
                    <th>Email Address</th>
                    <th>Hours Worked</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in this.filtered_members" :key="row.id" :class="{'has-background-danger-dark' : row.blocked==1, 'has-text-white' : row.blocked==1}">
                    <td><router-link :to="'/members/' + row.id" target="_blank"><button class="button is-small is-link" >View</button></router-link></td>
                    <td>{{ row.forename }}</td>
                    <td>{{ row.surname}}</td>
                    <td>{{ row.email }}</td>
                    <td><span :class="{'has-text-danger' : row.hours_worked >= 20}">{{ row.hours_worked }}</span></td>
                    
                </tr>
                
                
            </tbody>
            
        </table>

       
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
    
      name: 'MembersDashboard',
        mixins: [MemberMixin],
        components: {
            //MemberGradeTags
        },
        data: function () {
            return {
                   search: '',
                   members: [],
                   loading: false,
                  
                   showModal: false,
                   order_by: "surname ASC",
                   twenty_hours_only: 1,
                   show_all_members: 0
            }
        },
        methods: {
            update20Hours() {
                if(this.show_all_members == 1)
                {
                    this.show_all_members = 0
                }
                else {
                    this.show_all_members = 1
                }
                this.get20HoursMembers()
            },
            async get20HoursMembers()
            {
                if(this.order_by == "undefined" || this.order_by == "" || this.order_by == undefined)
                {
                    this.order_by = ""
                }

                this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "members?search=" + this.search + "&order_by=" + this.order_by + "&twenty_hours_only=" + this.twenty_hours_only + "&show_all_members=" + this.show_all_members).then(
                    (response) => {
                        this.members = response 
                        this.loading = false
                    }
                );
            },
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.get20HoursMembers(this.order_by)
            });
        
        },
        computed : {
                filtered_members: function () {
                
                let filtered_members =  this.members.filter(function(u) {
                    if(  u.active == 1) 
                    {
                    return true
                    }
                    else
                    {
                    return false
                    }
                })

                if(this.search != '' && this.search != null)
                {
                    filtered_members = filtered_members.filter(
                        row => (row.name != null && row.name.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.email != null && row.email.toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }

                return filtered_members
            
            },
        }
    }
</script>