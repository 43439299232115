<template>
    <div>
        <h3 class="title is-4">Member Exclusions 
            <button class="button is-success is-small" @click="showAddForm = true" :disabled="loading==true || working==true" v-if="showAddForm == false">Add New Exclusion</button>
            <button class="button is-danger is-small" @click="showAddForm = false" :disabled="loading==true || working==true" v-if="showAddForm == true">Exit without Saving</button>
        </h3>
        
        <div class="notification is-warning" v-if="loading==true">Loading exclusions ... </div>
        <div class="notification is-info" v-if="loading==false && exclusions.length == 0 && this.showAddForm == false">There are no exclusions to show. </div>
        <table class="table is-fullwidth is-hoverable" v-if="loading==false && exclusions.length > 0 && this.showAddForm == false">
            <thead>
                <tr>
                    <th>Client</th>
                    <th>Ward</th>
                    <th>Notes</th>
                    
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in exclusions" :key="row.id">
                    <td>{{ row.client_name}}</td>
                    <td>{{ row.ward_name}}</td>
                    <td>{{ row.notes }}</td>
                    <td ><button class="button is-small is-danger" @click="removeExclusion(row.id)">Remove</button></td>
                </tr>
                
            </tbody>
        </table>

        <div class="box" v-if="this.showAddForm == true">	
              <form @submit.prevent="saveMemberExclusion()">
                    <div class="field" >
                          <label class="label">Client</label>
                          <div class="control">
                              <select class="input"  v-model="client_id"  required>
                                <option value="">Please select</option>
                                <option :value="row.id" v-for="row in clients" :key="row.id">{{ row.name }}</option>
                              </select>
                             
                          </div>
                      </div>

                      <div class="field" >
                          <label class="label">Ward</label>
                          <div class="control">
                              <select class="input"  v-model="ward_id"  >
                                <option value="">Please select</option>
                                <option :value="row.id" v-for="row in wards" :key="row.id">{{ row.name }}</option>
                              </select>
                             
                          </div>
                      </div>
                
                      <div class="field" >
                          <label class="label">Reason</label>
                          <div class="control">
                              <input class="input" type="text" v-model="notes"  required>
                             
                          </div>
                      </div>

                      <div class="field is-grouped" >
                          <div class="control">
                              <button type="submit" class="button is-success" :disabled="working===true">Save Exclusion</button>
                          </div>
                      </div>
                  </form>
            
          </div>

    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';


import MemberService from '@/services/MemberService.js';
import UtilityService from '@/services/UtilityService.js';

export default {
    name: 'Exclusions',
  data() {
    return {
      loading: true,
      working: false,
      exclusions: [],
      showAddForm: false,
      clients: [],
      wards: [],
      client_id: '',
      ward_id: '',
      notes: ''
      
    }
  },
  mixins: [MemberMixin],
  props: ['member_id'],
  methods: {
       
        async getClients() {
            UtilityService.getSimpleApiData(this.accessToken, "clients").then(
                response => this.clients = response 
            );
        },
        async getWards() {
                UtilityService.getSimpleApiData(this.accessToken, "wards?client_id=" + this.client_id).then(
                    response => this.wards = response 
                );
            },
        async getMemberExclusions() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/exclusions")
            .then(
                (exclusions => {
                    this.$set(this, "exclusions", exclusions);
                    this.loading=false
                }).bind(this)
            );
        },
        async removeExclusion(member_exclusion_id) {
            this.working = true
            if(confirm("Are you sure you want to remove this exclusion ?"))
            {
            
                var formdata = { 
                    _method: 'patch',
                    active : 0
                }; 

                MemberService.updateMemberExclusion(this.accessToken, formdata, member_exclusion_id)
                .then((response) => {
                    this.getMemberExclusions()
                    console.log(response)
                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            }
            else{
                this.working = false
                return false
            }
        },
        async saveMemberExclusion() {
                this.working = true
                var formdata = { 
                    client_id : this.client_id,
                    ward_id : this.ward_id,
                   member_id: this.member_id,
                   notes: this.notes
                    
                }; 

                MemberService.saveMemberExclusion(this.accessToken, formdata)
                .then((response) => {
                    //this.$router.push("/bookings/new");
                    this.getMemberExclusions()
                    this.showAddForm = false
                    this.working = false
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
      
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberExclusions()
                this.getClients()
            });
        
    },
    computed : {
       
    },
    watch: {
            client_id: function () {
                this.getWards()
                
            },
        }
}
</script>