<template>
    <div class="modal" :class="{'is-active': showModal==true}">
        <div class="modal-background"></div>

        <div class="notification is-warning" v-if="loading== true">
            Loading booking ...
        </div>

        <div class="modal-card" v-if="loading == false">
            <header class="modal-card-head">
            <p class="modal-card-title">View Booking - {{ this.booking_id }}</p>
            <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body" >
                
                <div class="notification is-danger" v-if="loading == false  && booking == ''">
                    Booking details could not be found
                </div>

                <div v-if="loading == false && booking != ''">
                    <form>
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label">Reference</label>
                            </div>
                            <div class="field-body">
                                <div class="field ">
                                    <div class="control">
                                        <input class="input" type="text" v-model="booking.reference"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label">Date</label>
                            </div>
                            <div class="field-body">
                                <div class="field ">
                                    <div class="control">
                                        <input class="input" type="date" v-model="booking.start_date"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label">Time</label>
                            </div>
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <div class="control is-narrow" >
                                        <input class="input" type="time" v-model="booking.start_time"/>
                                    </div>
                                </div>
                                <div class="field is-narrow">
                                    <div class="control is-narrow" >
                                        <input class="input" type="time" v-model="booking.end_time"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="notification is-warning" v-if="booking.booking_status_id == 1">
                            Booking has not yet been assigned
                        </div>
                        <div class="notification is-warning" v-if="booking.booking_status_id == 3 || booking.booking_status_id == 4">
                            Booking has been assigned to 
                        </div>
                    </form>

                </div>

                
            </section>
            
            <footer class="modal-card-foot" >
                <button class="button" @click="closeModal()" :disabled="working==true">Close</button>
            </footer>
        </div>
        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
//import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';


export default {
    
      name: 'NewBookings',
        mixins: [MemberMixin],
        components: {
            
        },
        props: ['booking_id', 'showModal'],
        data: function () {
            return {
                   search: '',
                   booking: [],
                   loading: true,
                   searchPerformed: false,
                   assigned:false,
                   alertText: '',
                   working:false,
                   loadingMembers: true
            }
        },
        methods: {
            async getBooking() {
                this.loading=true
                
                UtilityService.getSimpleApiData(this.accessToken, "bookings/" + this.booking_id)
                .then(
                    (booking => {
                        this.$set(this, "booking", booking);
                        this.loading = false
                    }).bind(this)
                );
            },
            closeModal() {
                this.$emit('closeModal')
            }
           
            
        },
        computed : {
           
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                
            });
        
        },
        watch: {
            booking_id() {
                this.getBooking();
               
            }
        },
    }
</script>