<template>
    <div>
        
        <div class="notification is-warning" v-if="loading==true">Loading work experience ... </div>
        <div class="notification is-info" v-if="loading==false && work_experience.length == 0">There are no work experience details to show. </div>
        <table class="table is-fullwidth is-hoverable is-size-7" v-if="loading==false && work_experience.length > 0">
            <thead>
                <tr>
                    <th>Dates</th>
                    <th>Employer</th>
                    <th>Address</th>
                    <th>Telephone</th>
                    <th>Job Title</th>
                    <th>Roles</th>
                    <th>Reason Leaving</th>
                    <th>Qualifications</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in work_experience" :key="row.id">
                    <td>{{ row.date_from}} to {{ row.date_to}}</td>
                    <td>{{ row.employer_name}}</td>
                    <td>{{ row.address}}</td>
                    <td>{{ row.telephone}}</td>
                    <td>{{ row.job_title}}</td>
                    <td>{{ row.roles}}</td>
                    <td>{{ row.reason_for_leaving}}</td>
                    <td>{{ row.qualifications}}</td>
                </tr>
                
            </tbody>
        </table>

    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberService from '@/services/MemberService.js';
import UtilityService from '@/services/UtilityService.js';

export default {
    name: 'Education',
  data() {
    return {
      loading: true,
      working: false,
      work_experience: [],
      
    }
  },
  mixins: [MemberMixin],
  props: ['member_id'],
  methods: {
        
       
        async getMemberWorkExperience() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/work_experience")
            .then(
                (work_experience => {
                    this.$set(this, "work_experience", work_experience);
                    this.loading=false
                }).bind(this)
            );
        },
      
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberWorkExperience()
            });
        
    },
    computed : {
       
    }
}
</script>