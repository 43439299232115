<template>
    <div class="modal " :class="{'is-active': showEmailModal==true}">
        <div class="modal-background"></div>
        <div class="modal-card"  style="width:840px;max-width:100%">
            <header class="modal-card-head">
                <p class="modal-card-title">Send Email</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body" >
                <div class="notification is-warning" v-if="loading == true">
                    Loading member details ...
                </div>
                
                
                <form @submit.prevent="sendEmail()" v-if="loading==false">
                    <div class="field">
                    <label class="label">Member</label>
                    <div class="control">
                        <input class="input" type="text" :value="this.member.forename + ' ' + this.member.surname" disabled>
                    </div>
                    </div>
                    <div class="field">
                    <label class="label">Email Address</label>
                    <div class="control">
                        <input class="input" type="text" v-model="this.member.email" readonly disabled>
                    </div>
                    </div>
                    <div class="field">
                    <label class="label">Subject</label>
                    <div class="control">
                        <input class="input" type="text" v-model="email_subject" required>
                    </div>
                    </div>
                    <div class="field" >
                        <label class="label">Email Content</label>
                        <div class="control">
                            <textarea class="textarea" placeholder="" v-model="email_message" required></textarea>
                        </div>
                    </div>
                    <div class="field is-grouped">
                    <div class="control">
                        <button class="button is-success" :disabled="working==true">Send Message</button>
                    </div>
                    
                    </div>
                </form>

                
            </section>
            
            <footer class="modal-card-foot" >
            <button class="button" @click="closeModal()" :disabled="working==true">Cancel</button>
            </footer>
        </div>
        
       
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberService from '@/services/MemberService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
    
      name: 'EmailModal',
        mixins: [MemberMixin],
        components: {
            
        },
        props: ['member_id', 'showEmailModal', 'emailText', 'emailSubject'],
        data: function () {
            return {
                   search: '',
                   booking: [],
                   searchPerformed: false,
                   assigned:false,
                   alertText: '',
                   member: [],
                   working:false,
                   loading: true,
                   email_subject: this.emailSubject,
                   email_message: this.emailText,
                   
            }
        },
        methods: {
            async getMember() {
                this.loading=true
                
                UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id)
                .then(
                    (member => {
                        this.$set(this, "member", member);
                        this.loading = false
                    }).bind(this)
                );
            },
            
           
            async sendEmail() {
                this.working = true

                if(confirm('Please double check the email text and subject you want to send and press OK to continue ?'))
                {
                    var formdata = { 
                        subject : this.email_subject,
                        email_message : this.email_message,
                    }; 

                    MemberService.sendEmail(this.accessToken, this.member.id, formdata)
                    .then(
                        () => {
                            this.working=false;
                            this.email_message = ''
                            this.email_subject = ''
                            alert("Email Sent")
                            this.closeModal()

                        }
                    ).catch((error) => { 
                        
                    if (error.response.status == 409) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.response.data.message)
                    } 
                    this.working=false;
                });
                }
                else{
                    this.working=false;
                }

                
            },
            
            async closeModal() {
                this.$emit('close')
            },
            
        },
        computed : {
           
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                
            });
        
        },
        watch: {
            
            showEmailModal() {
                this.getMember();
                
            },
            emailText() {
                this.email_message = this.emailText
                
            }
        },
    }
</script>