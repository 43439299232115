<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading grades ...
        </div>
        <div class="notification is-danger" v-if="loading == false && grades.length == 0">
            No grades can be found
        </div>

        <div class="is-pulled-right" v-if="loading == false" style="display:none">
            <input type="text" class="input" v-model="search" placeholder="Search ..."/>
        </div>

        <table class="table is-fullwidth is-striped is-hoverable" v-if="loading == false && grades.length > 0">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Short Name</th>
                    <th>Code</th>
                    <th>BSO Code</th>
                    <th>Internal</th>
                    <th>VAT</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in grades" :key="row.id">
                    <td>{{ row.name }}</td>
                    <td>{{ row.short_name }}</td>
                    <td>{{ row.code }}</td>
                    <td>{{ row.bso_code }}</td>
                    <td><div class="tag is-info" v-if="row.internal==1">Yes</div></td>
                    <td><div class="tag is-danger" v-if="row.vat==1">Yes</div></td>
                    
                </tr>
               
            </tbody>

        </table>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'ClientGroups',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   grades: [],
                   loading: false,
                   accessToken: ''
                  
            }
        },
        methods: {
            async getGrades() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "grades?include_internal=1")
                .then(
                    (grades => {
                        this.$set(this, "grades", grades);
                        this.loading=false
                    }).bind(this)
                );
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getGrades();
                
            });
        
        },
        computed : {
            
        }
    }
</script>