import axios from "axios"

let API_NAME = process.env.VUE_APP_API_NAME;

export default {
	async getStones(accessToken) {
		let res = await axios.get(API_NAME + "/stones", {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
  async getStoneTypes(accessToken) {
	let res = await axios.get(API_NAME + "/stone_types", {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		});
  return res.data;
},


  
  
}