import axios from "axios"

let API_NAME = process.env.VUE_APP_API_NAME;

export default {
	async updateApplicationStage(accessToken, formdata, id) {
		let res = await axios.post(API_NAME + "/application_stages/" + id,  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async createApplicationStage(accessToken, formdata) {
		let res = await axios.post(API_NAME + "/application_stages",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async updateMemberApplication(accessToken, formdata, id) {
		let res = await axios.post(API_NAME + "/member_applications/" + id,  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async clearApplication(accessToken, formdata, id) {
		let res = await axios.post(API_NAME + "/member_applications/" + id + "/clear",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async updateMemberExclusion(accessToken, formdata, id) {
		let res = await axios.post(API_NAME + "/member_exclusions/" + id,  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async updateMemberDocument(accessToken, formdata, id) {
		let res = await axios.post(API_NAME + "/member_documents/" + id,  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async updateMemberEmergencyContact(accessToken, formdata, member_id, emergency_contact_id) {
		let res = await axios.post(API_NAME + "/members/" + member_id + "/emergency_contacts/" + emergency_contact_id,  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async updateMemberAccessNi(accessToken, formdata, id) {
		let res = await axios.post(API_NAME + "/members/" + id + "/access_ni",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async getAlerts(accessToken) {
		let res = await axios.get(API_NAME + "/alerts", {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
	async saveAlert(accessToken, formdata) {
		let res = await axios.post(API_NAME + "/alerts",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async saveMemberExclusion(accessToken, formdata) {
		let res = await axios.post(API_NAME + "/member_exclusions",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async saveMemberDocument(accessToken, formdata) {
		let res = await axios.post(API_NAME + "/member_documents",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async saveMemberAvailability(accessToken, formdata) {
		let res = await axios.post(API_NAME + "/member_availabilitys",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async deleteMemberAvailability(accessToken, formdata) {
		let res = await axios.post(API_NAME + "/member_availabilitys/delete",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  
	  async saveMemberEmergencyContact(accessToken, member_id, formdata) {
		let res = await axios.post(API_NAME + "/members/" + member_id + "/emergency_contacts",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async saveMember(accessToken, formdata) {
		let res = await axios.post(API_NAME + "/members",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  
	  async sendTestAppNotification(accessToken, formdata, member_id) {
		let res = await axios.post(API_NAME + "/members/" + member_id + "/send_test_app_notification",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async updateMember(accessToken, formdata, member_id) {
		let res = await axios.post(API_NAME + "/members/" + member_id,  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async uploadMemberImage(accessToken, formdata, member_id) {
		let res = await axios.post(API_NAME + "/members/" + member_id + "/member_image",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`,
			  'Content-Type': 'multipart/form-data'
			}
			
		  });
		  
		return res.data;
	  },
	  async updateMemberAccessNi2023(accessToken, formdata, member_access_ni_id) {
		let res = await axios.post(API_NAME + "/member_access_nis/" + member_access_ni_id,  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async saveMemberGrade(accessToken, formdata) {
		let res = await axios.post(API_NAME + "/member_grades",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async removeMemberGrade(accessToken, formdata) {
		let res = await axios.post(API_NAME + "/member_grades/delete",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  
	  async saveAccessNi(accessToken, formdata) {
		let res = await axios.post(API_NAME + "/member_access_nis",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async blockMember(accessToken, formdata, member_id) {
		let res = await axios.post(API_NAME + "/members/" + member_id + "/block",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
		  });
		  
		return res.data;
	  },
	  async unBlockMember(accessToken, formdata, member_id) {
		let res = await axios.post(API_NAME + "/members/" + member_id + "/unblock",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
		  });
		  
		return res.data;
	  },
	  async archiveMember(accessToken, formdata, member_id) {
		let res = await axios.post(API_NAME + "/members/" + member_id + "/archive",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
		  });
		  
		return res.data;
	  },
	  async saveMemberCourse(accessToken, formdata) {
		let res = await axios.post(API_NAME + "/member_courses",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async getMember(accessToken, member_id) {
		let res = await axios.get(API_NAME + "/members/" + member_id, {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
	
	
	async getMemberGrades(accessToken, member_id) {
		let res = await axios.get(API_NAME + "/members/" + member_id + "/grades", {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
	async getUpcomingBookings(accessToken, member_id) {
		let res = await axios.get(API_NAME + "/members/" + member_id + '/bookings_assigned', {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
	async getCompletedBookings(accessToken, member_id) {
		let res = await axios.get(API_NAME + "/members/" + member_id + '/bookings_completed', {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
	async getAssignedBookings(accessToken, member_id) {
		let res = await axios.get(API_NAME + "/members/" + member_id + '/bookings_assigned_only', {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
	async sendSms(accessToken, member_id, formdata) {
		let res = await axios.post(API_NAME + "/members/" + member_id + '/send_sms',  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async sendEmail(accessToken, member_id, formdata) {
		let res = await axios.post(API_NAME + "/members/" + member_id + '/send_email',  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  
	  async saveMemberNote(accessToken, formdata) {
		let res = await axios.post(API_NAME + "/member_notes",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
	  async getUser(accessToken, user_id) {
		let res = await axios.get(API_NAME + "/users/" + user_id, {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
	async getPatient(accessToken, patient_id) {
		let res = await axios.get(API_NAME + "/patients/" + patient_id, {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
  
  
}